import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { PrimaryButton } from '../../../../components';
import css from './HeroSection.module.css';

export default function HeroSection() {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const onClick = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
  };

  const onSellAnItemClick = () => {
    history.push(createResourceLocatorString('NewListingPage', routeConfiguration, {}, {}));
  };

  return (
    <div className={css.root}>
      <div className={css.heroWrapper}>
        <div className={css.contentWrapper}>
          <div className={css.title}>
            <FormattedMessage id="LandingPage.HeroSection.title" />
          </div>
          <div className={css.content}>
            <FormattedMessage id="LandingPage.HeroSection.content" />
          </div>
          <div className={css.buttons}>
            <PrimaryButton className={css.btn} onClick={onClick}>
              <FormattedMessage id="LandingPage.HeroSection.shopNowBtn" />
            </PrimaryButton>
            <PrimaryButton
              className={classNames(css.btn, css.transparent)}
              onClick={onSellAnItemClick}
            >
              <FormattedMessage id="LandingPage.HeroSection.sellAnItem" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}
