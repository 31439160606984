import { getDonationsInformation, queryDonateHistory, updateDonationItem } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

const PER_PAGE = 50;

// ================ Action types ================ //
const GET_DONATION_REQUEST = 'app/DonationsPage/GET_DONATION_REQUEST';
const GET_DONATION_SUCCESS = 'app/DonationsPage/GET_DONATION_SUCCESS';
const GET_DONATION_ERROR = 'app/DonationsPage/GET_DONATION_ERROR';

const UPDATE_TRACKING_DONATION_REQUEST = 'app/DonationsPage/UPDATE_TRACKING_DONATION_REQUEST';
const UPDATE_TRACKING_DONATION_SUCCESS = 'app/DonationsPage/UPDATE_TRACKING_DONATION_SUCCESS';
const UPDATE_TRACKING_DONATION_ERROR = 'app/DonationsPage/UPDATE_TRACKING_DONATION_ERROR';

const GET_TRACKING_DONATION_REQUEST = 'app/DonationsPage/GET_TRACKING_DONATION_REQUEST';
const GET_TRACKING_DONATION_SUCCESS = 'app/DonationsPage/GET_TRACKING_DONATION_SUCCESS';
const GET_TRACKING_DONATION_ERROR = 'app/DonationsPage/GET_TRACKING_DONATION_ERROR';

const GET_ALL_TRACKING_DONATION_REQUEST = 'app/DonationsPage/GET_ALL_TRACKING_DONATION_REQUEST';
const GET_ALL_TRACKING_DONATION_SUCCESS = 'app/DonationsPage/GET_ALL_TRACKING_DONATION_SUCCESS';
const GET_ALL_TRACKING_DONATION_ERROR = 'app/DonationsPage/GET_ALL_TRACKING_DONATION_ERROR';
// ================ Reducer ================ //

const initialState = {
  donationsInformation: null,
  getDonationsInformationError: null,
  getDonationsInformationInProgress: false,
  updateTrackingDonationError: null,
  updateTrackingDonationInProgress: false,
  getTrackingDonationError: null,
  getTrackingDonationInProgress: false,
  donatedData: null,
  allDonatedData: null,
  pagination: null,
  page: 1,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_DONATION_REQUEST:
      return {
        ...state,
        getDonationsInformationInProgress: true,
        getDonationsInformationError: null,
        donationsInformation: null,
      };
    case GET_DONATION_SUCCESS:
      return { ...state, getDonationsInformationInProgress: false, donationsInformation: payload };
    case GET_DONATION_ERROR:
      return {
        ...state,
        getDonationsInformationInProgress: false,
        getDonationsInformationError: payload,
        donationsInformation: null,
      };
    case UPDATE_TRACKING_DONATION_REQUEST:
      return {
        ...state,
        updateTrackingDonationInProgress: true,
        updateTrackingDonationError: null,
      };
    case UPDATE_TRACKING_DONATION_SUCCESS:
      return { ...state, updateTrackingDonationInProgress: false };
    case UPDATE_TRACKING_DONATION_ERROR:
      return {
        ...state,
        updateTrackingDonationInProgress: false,
        updateTrackingDonationError: payload,
      };
    case GET_TRACKING_DONATION_REQUEST:
      return {
        ...state,
        getTrackingDonationInProgress: true,
        getTrackingDonationError: null,
      };
    case GET_TRACKING_DONATION_SUCCESS: {
      return {
        ...state,
        getTrackingDonationInProgress: false,
        donatedData: state.allDonatedData.slice(
          (payload.queryPage - 1) * PER_PAGE,
          payload.queryPage * PER_PAGE
        ),
        pagination: {
          ...state.pagination,
          page: payload.queryPage,
        },
      };
    }
    case GET_TRACKING_DONATION_ERROR:
      return {
        ...state,
        getTrackingDonationInProgress: false,
        getTrackingDonationError: payload,
      };
    case GET_ALL_TRACKING_DONATION_REQUEST:
      return {
        ...state,
        getTrackingDonationInProgress: true,
        getTrackingDonationError: null,
      };
    case GET_ALL_TRACKING_DONATION_SUCCESS: {
      const totalItems = payload?.donatedData?.length || 0;
      const totalPage = Math.ceil(totalItems / PER_PAGE);
      return {
        ...state,
        getTrackingDonationInProgress: false,
        allDonatedData: payload.donatedData,
        pagination: {
          totalItems,
          totalPages: totalPage,
          page: Number(payload.queryPage),
          perPage: PER_PAGE,
        },
      };
    }
    case GET_ALL_TRACKING_DONATION_ERROR:
      return {
        ...state,
        getTrackingDonationInProgress: false,
        getTrackingDonationError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const getDonationRequest = () => ({ type: GET_DONATION_REQUEST });
const getDonationSuccess = donationsInformation => ({
  type: GET_DONATION_SUCCESS,
  payload: donationsInformation,
});
const getDonationError = error => ({
  type: GET_DONATION_ERROR,
  payload: error,
  error: true,
});

const updateTrackingDonationRequest = () => ({ type: UPDATE_TRACKING_DONATION_REQUEST });
const updateTrackingDonationSuccess = () => ({
  type: UPDATE_TRACKING_DONATION_SUCCESS,
});
const updateTrackingDonationError = error => ({
  type: UPDATE_TRACKING_DONATION_ERROR,
  payload: error,
  error: true,
});
const getTrackingDonationRequest = () => ({ type: GET_TRACKING_DONATION_REQUEST });
const getTrackingDonationSuccess = data => ({
  type: GET_TRACKING_DONATION_SUCCESS,
  payload: data,
});
const getTrackingDonationError = error => ({
  type: GET_TRACKING_DONATION_ERROR,
  payload: error,
  error: true,
});

const getAllTrackingDonationRequest = () => ({ type: GET_ALL_TRACKING_DONATION_REQUEST });
const getAllTrackingDonationSuccess = data => ({
  type: GET_ALL_TRACKING_DONATION_SUCCESS,
  payload: data,
});
const getAllTrackingDonationError = error => ({
  type: GET_ALL_TRACKING_DONATION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const getDonations = () => async dispatch => {
  try {
    dispatch(getDonationRequest());
    const res = await getDonationsInformation();

    return dispatch(getDonationSuccess(res.data));
  } catch (e) {
    return dispatch(getDonationError(storableError(e)));
  }
};

export const getDonateHistory = ({ page }) => dispatch => {
  try {
    dispatch(getTrackingDonationRequest(page));
    return dispatch(
      getTrackingDonationSuccess({
        queryPage: page,
      })
    );
  } catch (e) {
    return dispatch(getTrackingDonationError(storableError(e)));
  }
};

export const getAllDonationHistory = () => async dispatch => {
  try {
    dispatch(getAllTrackingDonationRequest());
    const res = await queryDonateHistory();
    return dispatch(getAllTrackingDonationSuccess(res.data));
  } catch (e) {
    return dispatch(getAllTrackingDonationError(storableError(e)));
  }
};

export const updateTrackingDonation = values => async dispatch => {
  try {
    dispatch(updateTrackingDonationRequest());
    await updateDonationItem(values);
    await dispatch(getDonations());
    await dispatch(getAllDonationHistory());
    dispatch(getDonateHistory({ page: 1 }));
    return dispatch(updateTrackingDonationSuccess());
  } catch (e) {
    dispatch(updateTrackingDonationError(storableError(e)));
    throw e;
  }
};

export const loadData = (params, search) => async dispatch => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  await dispatch(getDonations());
  await dispatch(getAllDonationHistory());
  dispatch(getDonateHistory({ page }));
};
