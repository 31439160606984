import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import NamedLink from '../../../NamedLink/NamedLink';

import css from '../TopbarMobileMenu.module.css';

const NavigationLink = ({ text, name, currentPage }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  return (
    <NamedLink
      key={text}
      className={classNames(css.navigationLink, currentPageClass(name))}
      name={name}
    >
      <FormattedMessage id={`TopbarMobileMenu.${text}`} />
    </NamedLink>
  );
};

NavigationLink.defaultProps = {
  currentPage: null,
};

NavigationLink.propTypes = {
  text: string.isRequired,
  name: string.isRequired,
  currentPage: string,
};

export default NavigationLink;
