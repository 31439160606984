import React from 'react';
import { object, string } from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import css from './Category.module.css';
import { imageVariants } from '../../../../util/configHelpers';
import ResponsiveImage from '../../../ResponsiveImage/ResponsiveImage';

const Category = props => {
  const { img, title, blockId, url } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(url);
  };

  return (
    <div key={blockId} className={css.card} onClick={handleClick}>
      <ResponsiveImage rootClassName={css.img} alt={title} image={img} variants={imageVariants} />
      <div className={css.text}>{title.toLowerCase()}</div>
    </div>
  );
};

Category.propTypes = {
  img: object.isRequired,
  title: string.isRequired,
  blockId: string.isRequired,
  url: string,
};

Category.defaultProps = {
  url: '',
};

export default Category;
