import get from 'lodash/get';

// eslint-disable-next-line import/prefer-default-export
export const getUserLikedListings = user =>
  get(user, 'attributes.profile.protectedData.likedListings', []);

export const userHasLikedListing = (user, listingId) => {
  return getUserLikedListings(user).includes(listingId);
};

export const userIsVerified = user => {
  return get(user, 'attributes.profile.metadata.verified', false);
};

export const getUserShippingAddress = user => {
  return get(user, 'attributes.profile.privateData.shippingAddress', null);
};

export const getUserReferralCode = user =>
  get(user, 'attributes.profile.metadata.referralCode', null);
