/* eslint-disable import/no-extraneous-dependencies */
import { deleteUser, getAuth } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import { storableError } from '../../util/errors';
import { deleteUserAccount } from '../../util/api';
import { ERROR_CODE_FIREBASE_REQUIRE_RECENT_LOGIN } from '../../util/types';
import { transitions } from '../../transactions/transactionProcessPurchase';
import { denormalisedResponseEntities } from '../../util/data';

// Constants
const nonFinalTransitions = [
  transitions.REQUEST_PAYMENT,
  transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
  transitions.MAKE_AN_OFFER,
  transitions.CUSTOMER_COUNTER,
  transitions.CUSTOMER_ACCEPT,
  transitions.REQUEST_PURCHASE,
  transitions.CONFIRM_PAYMENT,
  transitions.MARK_RECEIVED,
  transitions.DISPUTE,
  transitions.REVIEW_1_BY_CUSTOMER,
  transitions.REVIEW_1_BY_PROVIDER,
];

// ================ Action types ================ //

export const DELETE_ACCOUNT_REQUEST = 'app/DeleteAccountPage/DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'app/DeleteAccountPage/DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'app/DeleteAccountPage/DELETE_ACCOUNT_ERROR';
export const DELETE_ACCOUNT_CLEANUP = 'app/DeleteAccountPage/DELETE_ACCOUNT_CLEANUP';

export const DELETE_ACCOUNT_CLEAR = 'app/DeleteAccountPage/DELETE_ACCOUNT_CLEAR';
export const DELETE_ACCOUNT_REQUIRE_RECENT_LOGIN_ERROR =
  'app/DeleteAccountPage/DELETE_ACCOUNT_REQUIRE_RECENT_LOGIN_ERROR';
export const DELETE_ACCOUNT_HAS_INCOMPLETE_TRANSACTION_ERROR =
  'app/DeleteAccountPage/DELETE_ACCOUNT_HAS_INCOMPLETE_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  deleteAccountError: null,
  deleteAccountInProgress: false,
  accountDeleted: false,
  deleteAccountRequireRecentLoginError: false,
  numberOfIncompleteTransactions: 0,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteAccountInProgress: true,
        deleteAccountError: null,
        accountDeleted: false,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return { ...state, deleteAccountInProgress: false, accountDeleted: true };
    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccountInProgress: false,
        deleteAccountError: payload,
      };

    case DELETE_ACCOUNT_REQUIRE_RECENT_LOGIN_ERROR:
      return { ...state, deleteAccountRequireRecentLogin: true };
    case DELETE_ACCOUNT_HAS_INCOMPLETE_TRANSACTION_ERROR:
      return { ...state, numberOfIncompleteTransactions: payload };

    case DELETE_ACCOUNT_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const deleteAccountRequest = () => ({ type: DELETE_ACCOUNT_REQUEST });
export const deleteAccountSuccess = () => ({ type: DELETE_ACCOUNT_SUCCESS });
export const deleteAccountError = error => ({
  type: DELETE_ACCOUNT_ERROR,
  payload: error,
  error: true,
});
export const deleteAccountRequireRecentLoginError = () => ({
  type: DELETE_ACCOUNT_REQUIRE_RECENT_LOGIN_ERROR,
});

export const deleteAccountHasIncompleteTransactionError = payload => ({
  type: DELETE_ACCOUNT_HAS_INCOMPLETE_TRANSACTION_ERROR,
  payload,
});

export const deleteAccountClear = () => ({ type: DELETE_ACCOUNT_CLEAR });

// ================ Thunks ================ //

const canDeleteUser = async sdk => {
  try {
    const transactionRes = await sdk.transactions.query({
      lastTransitions: nonFinalTransitions,
    });
    const data = denormalisedResponseEntities(transactionRes);
    if (data.length > 0) {
      return {
        canDelete: false,
        incompleteTransactions: data.length,
      };
    }
    return {
      canDelete: true,
      incompleteTransactions: 0,
    };
  } catch (e) {
    return {
      canDelete: false,
      incompleteTransactions: 0,
    };
  }
};

export const deleteAccount = params => async (dispatch, getState, sdk) => {
  dispatch(deleteAccountRequest());
  const { currentUserId } = params;
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    const { canDelete, incompleteTransactions } = await canDeleteUser(sdk);
    if (canDelete) {
      await deleteUser(user);
      await deleteUserAccount(currentUserId);
      dispatch(deleteAccountSuccess());
    } else {
      dispatch(deleteAccountHasIncompleteTransactionError(incompleteTransactions));
      dispatch(deleteAccountError('Cannot delete account with incomplete transactions'));
    }
  } catch (error) {
    if (error instanceof FirebaseError && error.code === ERROR_CODE_FIREBASE_REQUIRE_RECENT_LOGIN) {
      dispatch(deleteAccountRequireRecentLoginError());
    } else {
      dispatch(deleteAccountError(storableError(error)));
    }
    throw error;
  }
};
