import React from 'react';
import { array, string } from 'prop-types';
import NavigationLink from './NavigationLink';

import css from '../TopbarMobileMenu.module.css';

const NavigationLinks = ({ data, currentPage }) => {
  return (
    <div className={css.navigationLinks}>
      <div className={css.linksLeft}>
        {data
          ?.slice(0, 5)
          .map(link => (
            <NavigationLink
              key={link.text}
              name={link.name}
              text={link.text}
              currentPage={currentPage}
            />
          ))}
      </div>
      <div className={css.linksRight}>
        {data
          ?.slice(5, 10)
          .map(link => (
            <NavigationLink
              key={link.text}
              name={link.name}
              text={link.text}
              currentPage={currentPage}
            />
          ))}
      </div>
    </div>
  );
};

NavigationLinks.propTypes = {
  data: array.isRequired,
  currentPage: string.isRequired,
};

export default NavigationLinks;
