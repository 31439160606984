import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
const SAVE_ADDRESSES_REQUEST = 'app/LocationPage/SAVE_ADDRESSES_REQUEST';
const SAVE_ADDRESSES_SUCCESS = 'app/LocationPage/SAVE_ADDRESSES_SUCCESS';
const SAVE_ADDRESSES_ERROR = 'app/LocationPage/SAVE_ADDRESSES_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveAddressesError: null,
  saveAddressesInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ADDRESSES_REQUEST:
      return {
        ...state,
        saveAddressesInProgress: true,
        saveAddressesError: null,
      };
    case SAVE_ADDRESSES_SUCCESS:
      return { ...state, saveAddressesInProgress: false };
    case SAVE_ADDRESSES_ERROR:
      return { ...state, saveAddressesInProgress: false, saveAddressesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const saveAddressesRequest = () => ({ type: SAVE_ADDRESSES_REQUEST });
const saveAddressesSuccess = () => ({ type: SAVE_ADDRESSES_SUCCESS });
const saveAddressesError = error => ({
  type: SAVE_ADDRESSES_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const saveAddresses = params => async (dispatch, getState, sdk) => {
  try {
    dispatch(saveAddressesRequest());
    const { addresses } = params;

    await sdk.currentUser.updateProfile({
      privateData: {
        addresses,
      },
    });
    await dispatch(saveAddressesSuccess());
    return dispatch(fetchCurrentUser());
  } catch (e) {
    return dispatch(saveAddressesError(storableError(e)));
  }
};

export const loadData = () => dispatch => dispatch(fetchCurrentUser());
