import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, object } from 'prop-types';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './LandingPage.module.css';
import { validProps } from '../PageBuilder/Field/Field';
import StaticPage from '../PageBuilder/StaticPage';
import { IconSpinner, LayoutComposer } from '../../components';
import { propTypes } from '../../util/types';
import {
  CategorySection,
  GetAppSection,
  HeroSection,
  HowToSell,
  NavBarSection,
  NewsSection,
  PromotionSection,
  ReCommerce,
} from './components';
import { getAssetsCategories } from '../../util/data';

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

export const CATEGORIES_KEY = 'categories';
export const LOVED_BRANDS_KEY = 'lovedbrands';
export const TRENDING_CATEGORIES_KEY = 'trending-categories';
const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LandingPageComponent = (props) => {
  const { pageAssetsData, inProgress } = props;
  const { meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta);
  const { sections } = pageAssetsData?.landingPage?.data || {};

  if (!sections) {
    return <LoadingSpinner />;
  }

  const categoryList = sections?.find((item) => {
    return item.sectionId === CATEGORIES_KEY;
  });
  const categoryData = getAssetsCategories(categoryList);

  const lovedBrandList = sections?.find((item) => {
    return item.sectionId === LOVED_BRANDS_KEY;
  });
  const lovedBrandsData = getAssetsCategories(lovedBrandList);

  const trendingList = sections?.find((item) => {
    return item.sectionId === TRENDING_CATEGORIES_KEY;
  });
  const trendingData = getAssetsCategories(trendingList);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  return (
    <StaticPage {...pageMetaProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {(composerProps) => {
          const { Topbar, Main } = composerProps;
          return (
            <>
              <div>
                <Topbar as="header" className={css.topbar}>
                  <TopbarContainer />
                </Topbar>
                <NavBarSection />
              </div>
              <Main as="main" className={css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <div className={css.container}>
                    <HeroSection />
                    <CategorySection
                      key={CATEGORIES_KEY}
                      items={categoryData?.items}
                      title={categoryData?.title}
                      type={CATEGORIES_KEY}
                    />
                    <PromotionSection />
                    <CategorySection
                      key={LOVED_BRANDS_KEY}
                      items={lovedBrandsData?.items}
                      title={lovedBrandsData?.title}
                      type={LOVED_BRANDS_KEY}
                    />
                    <CategorySection
                      key={TRENDING_CATEGORIES_KEY}
                      items={trendingData?.items}
                      title={trendingData?.title}
                      type={TRENDING_CATEGORIES_KEY}
                    />
                    {/* <HowToSell /> */}
                    <ReCommerce />
                    <NewsSection />
                    <GetAppSection />
                  </div>
                )}
              </Main>
              <FooterContainer />
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

LandingPageComponent.defaultProps = {
  pageAssetsData: null,
  inProgress: false,
  error: null,
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = (state) => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
