/* eslint-disable no-unused-vars */
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  OAuthProvider,
  linkWithCredential,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { googleProvider } from '../firebase/auth-google-provider-create';
import { auth } from '../firebase/firebase.config';
import defaultConfig from '../config/configDefault';
import { facebookProvider } from '../firebase/auth-facebook-provider-create';
import {
  ERROR_CODE_ACCOUNT_EXISTED_FACEBOOK,
  ERROR_CODE_ACCOUNT_EXISTED_GOOGLE,
  ERROR_CODE_FIREBASE_ACCOUNT_EXISTS,
  ERROR_CODE_FIREBASE_INVALID_LOGIN_CREDENTIALS,
} from '../util/types';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/auth/CONFIRM_ERROR';

export const CLEAR_SIGN_UP_ERROR = 'app/auth/CLEAR_SIGN_UP_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';
export const SOCIAL_LOGIN_ERROR = 'app/SOCIAL_LOGIN_ERROR';

export const STORE_SOCIAL_LOGIN_CREDENTIAL = 'app/STORE_SOCIAL_LOGIN_CREDENTIAL';
export const DELETE_SOCIAL_LOGIN_CREDENTIAL = 'app/DELETE_SOCIAL_LOGIN_CREDENTIAL';

// ================ Helper functions ================ //
const createUserParams = (user, useOtherParams = false) => {
  const {
    firebase: { firebaseEmailSSOIdpId, firebaseEmailSSOClientId },
  } = defaultConfig;
  const accessToken = user?.accessToken;
  const email = user?.email;
  const displayName = user?.displayName;
  const phoneNumber = user?.phoneNumber;
  const nameArr = displayName.split(' ');
  const otherParams = {
    email,
    firstName: nameArr[0],
    lastName: nameArr[1],
    displayName,
    protectedData: {
      phoneNumber,
    },
  };
  return {
    idpId: firebaseEmailSSOIdpId,
    idpClientId: firebaseEmailSSOClientId,
    idpToken: accessToken,
    ...(useOtherParams ? otherParams : {}),
  };
};

const handleErrorFirebaseAccountExists = (error, dispatch, errorCodeAccountExisted) => {
  if (error.code === ERROR_CODE_FIREBASE_ACCOUNT_EXISTS) {
    dispatch(socialLoginError(errorCodeAccountExisted));
    const credential = OAuthProvider.credentialFromError(error);
    dispatch(storeSocialLoginCredential(credential));
  } else {
    dispatch(confirmError(error));
  }
};

const initAuthenticatedIntercomSession = user => {
  const email = user?.email;
  const displayName = user?.displayName;
  window.Intercom('shutdown');
  window.Intercom('boot', {
    app_id: defaultConfig.intercom.appId,
    api_base: defaultConfig.intercom.baseUrl,
    email,
    name: displayName,
  });
};

const handleSocialLogin = async (provider, getState, dispatch) => {
  const result = await signInWithPopup(auth, provider);
  const { socialLoginCredential } = getState().auth;
  if (socialLoginCredential) {
    await linkWithCredential(result.user, socialLoginCredential);
    await dispatch(deleteSocialLoginCredential());
  }
  const { user } = result;
  const userParams = createUserParams(user, true);
  await dispatch(authWithIdp(userParams));
  await initAuthenticatedIntercomSession(user);
};

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,
  socialLoginError: null,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,

  // credentials
  socialLoginCredential: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
        socialLoginError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    case SOCIAL_LOGIN_ERROR:
      return { ...state, socialLoginError: payload, loginInProgress: false, loginError: null };

    case STORE_SOCIAL_LOGIN_CREDENTIAL:
      return { ...state, socialLoginCredential: payload };
    case DELETE_SOCIAL_LOGIN_CREDENTIAL:
      return { ...state, socialLoginCredential: null };

    case CLEAR_SIGN_UP_ERROR:
      return { ...state, signupError: null };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const clearSignupError = () => ({ type: CLEAR_SIGN_UP_ERROR });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });
export const socialLoginError = error => ({
  type: SOCIAL_LOGIN_ERROR,
  payload: error,
  error: true,
});

export const storeSocialLoginCredential = credential => ({
  type: STORE_SOCIAL_LOGIN_CREDENTIAL,
  payload: credential,
});

export const deleteSocialLoginCredential = () => ({
  type: DELETE_SOCIAL_LOGIN_CREDENTIAL,
});

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  try {
    const loginResult = await signInWithEmailAndPassword(auth, username, password);
    const { user } = loginResult;
    const { accessToken } = user;
    const userParams = {
      idpId: defaultConfig.firebase.firebaseEmailSSOIdpId,
      idpClientId: defaultConfig.firebase.firebaseEmailSSOClientId,
      idpToken: accessToken,
    };
    await dispatch(authWithIdp(userParams));
    const currentUser = await dispatch(fetchCurrentUser());
    const {
      attributes: {
        email,
        profile: { firstName },
      },
    } = currentUser;
    const intercomUser = {
      email,
      displayName: firstName,
    };
    await initAuthenticatedIntercomSession(intercomUser);
    return dispatch(loginSuccess());
  } catch (e) {
    if (e instanceof FirebaseError) {
      return dispatch(socialLoginError(ERROR_CODE_FIREBASE_INVALID_LOGIN_CREDENTIALS));
    }
    return dispatch(loginError(storableError(e)));
  }
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      window.Intercom('shutdown');
      window.Intercom('boot', {
        app_id: defaultConfig.intercom.appId,
        api_base: defaultConfig.intercom.baseUrl,
      });
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, ...rest } = params;

  const createdUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : { email, password, firstName, lastName, protectedData: { ...rest } };

  try {
    const createResponse = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = createResponse;
    const accessToken = user?.accessToken;

    const userParams = {
      idpId: defaultConfig.firebase.firebaseEmailSSOIdpId,
      idpClientId: defaultConfig.firebase.firebaseEmailSSOClientId,
      idpToken: accessToken,
      isManualCreated: true,
      ...omit(createdUserParams, ['password']),
    };
    await dispatch(authWithIdp(userParams));
    await initAuthenticatedIntercomSession(user);
    return dispatch(signupSuccess());
  } catch (e) {
    dispatch(signupError(storableError(e)));
    return log.error(e, 'signup-failed', {
      email: params.email,
      firstName: params.firstName,
      lastName: params.lastName,
    });
  }
};

export const signupWithGoogleFirebase = () => async (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  try {
    await handleSocialLogin(googleProvider, getState, dispatch);
  } catch (error) {
    handleErrorFirebaseAccountExists(error, dispatch, ERROR_CODE_ACCOUNT_EXISTED_GOOGLE);
  }
};
export const signupWithFacebookFirebase = () => async (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  try {
    await handleSocialLogin(facebookProvider, getState, dispatch);
  } catch (error) {
    handleErrorFirebaseAccountExists(error, dispatch, ERROR_CODE_ACCOUNT_EXISTED_FACEBOOK);
  }
};

export const authWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(() => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params, e });
      return dispatch(confirmError(storableError(e)));
    });
};
