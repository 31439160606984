export const transitions = {
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT: 'transition/request-payment',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',
  MAKE_AN_OFFER: 'transition/make-an-offer',
  PROVIDER_ACCEPT: 'transition/provider-accept',
  EXPIRE_CUSTOMER_OFFER: 'transition/expire-customer-offer',
  PROVIDER_DECLINE: 'transition/provider-decline',
  PROVIDER_COUNTER: 'transition/provider-counter',
  CUSTOMER_ACCEPT: 'transition/customer-accept',
  EXPIRE_PROVIDER_OFFER: 'transition/expire-provider-offer',
  CUSTOMER_DECLINE: 'transition/customer-decline',
  CUSTOMER_COUNTER: 'transition/customer-counter',
  EXPIRE_CONFIRM_PURCHASE: 'transition/expire-confirm-purchase',
  REQUEST_PURCHASE: 'transition/request-purchase',
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  EXPIRE_PAYMENT: 'transition/expire-payment',
  MARK_SHIPPED: 'transition/mark-shipped',
  AUTO_MARK_DELIVERED: 'transition/auto-mark-delivered',
  MARK_RECEIVED: 'transition/mark-received',
  AUTO_MARK_RECEIVED: 'transition/auto-mark-received',
  DISPUTE: 'transition/dispute',
  OPERATOR_DISPUTE: 'transition/operator-dispute',
  MARK_RECEIVED_FROM_DISPUTED: 'transition/mark-received-from-disputed',
  CANCEL: 'transition/cancel',
  CANCEL_FROM_DISPUTED: 'transition/cancel-from-disputed',
  AUTO_CANCEL_FROM_DISPUTED: 'transition/auto-cancel-from-disputed',
  AUTO_COMPLETE: 'transition/auto-complete',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
};

export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  CUSTOMER_MADE_OFFER: 'customer-made-offer',
  OFFER_ACCEPTED: 'offer-accepted',
  OFFER_EXPIRED: 'offer-expired',
  OFFER_DECLINED: 'offer-declined',
  PROVIDER_MADE_OFFER: 'provider-made-offer',
  PURCHASED: 'purchased',
  PAYMENT_EXPIRED: 'payment-expired',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  RECEIVED: 'received',
  DISPUTED: 'disputed',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
};

export const graph = {
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.MAKE_AN_OFFER]: states.CUSTOMER_MADE_OFFER,
      },
    },
    [states.REVIEWED]: {
      type: 'final',
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },
    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
      },
    },
    [states.CUSTOMER_MADE_OFFER]: {
      on: {
        [transitions.PROVIDER_ACCEPT]: states.OFFER_ACCEPTED,
        [transitions.EXPIRE_CUSTOMER_OFFER]: states.OFFER_EXPIRED,
        [transitions.PROVIDER_DECLINE]: states.OFFER_DECLINED,
        [transitions.PROVIDER_COUNTER]: states.PROVIDER_MADE_OFFER,
      },
    },
    [states.OFFER_ACCEPTED]: {
      on: {
        [transitions.EXPIRE_CONFIRM_PURCHASE]: states.OFFER_EXPIRED,
        [transitions.REQUEST_PURCHASE]: states.PENDING_PAYMENT,
      },
    },
    [states.OFFER_EXPIRED]: {},
    [states.OFFER_DECLINED]: {},
    [states.PROVIDER_MADE_OFFER]: {
      on: {
        [transitions.CUSTOMER_ACCEPT]: states.OFFER_ACCEPTED,
        [transitions.EXPIRE_PROVIDER_OFFER]: states.OFFER_EXPIRED,
        [transitions.CUSTOMER_DECLINE]: states.OFFER_DECLINED,
        [transitions.CUSTOMER_COUNTER]: states.CUSTOMER_MADE_OFFER,
      },
    },
    [states.PURCHASED]: {
      on: {
        [transitions.MARK_SHIPPED]: states.SHIPPED,
        [transitions.CANCEL]: states.CANCELED,
      },
    },
    [states.PAYMENT_EXPIRED]: {},
    [states.SHIPPED]: {
      on: {
        [transitions.AUTO_MARK_DELIVERED]: states.DELIVERED,
      },
    },
    [states.DELIVERED]: {
      on: {
        [transitions.MARK_RECEIVED]: states.RECEIVED,
        [transitions.AUTO_MARK_RECEIVED]: states.RECEIVED,
        [transitions.DISPUTE]: states.DISPUTED,
        [transitions.OPERATOR_DISPUTE]: states.DISPUTED,
      },
    },
    [states.RECEIVED]: {
      on: {
        [transitions.AUTO_COMPLETE]: states.COMPLETED,
      },
    },
    [states.DISPUTED]: {
      on: {
        [transitions.MARK_RECEIVED_FROM_DISPUTED]: states.RECEIVED,
        [transitions.CANCEL_FROM_DISPUTED]: states.CANCELED,
        [transitions.AUTO_CANCEL_FROM_DISPUTED]: states.CANCELED,
      },
    },
    [states.CANCELED]: {},
    [states.COMPLETED]: {
      on: {
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
  },
};

export const isRelevantPastTransition = transition => {
  return [
    transitions.CONFIRM_PAYMENT,
    transitions.CANCEL,
    transitions.DISPUTE,
    transitions.OPERATOR_DISPUTE,
    transitions.AUTO_COMPLETE,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.MAKE_AN_OFFER,
    transitions.CUSTOMER_ACCEPT,
    transitions.CUSTOMER_COUNTER,
    transitions.CUSTOMER_DECLINE,
    transitions.PROVIDER_ACCEPT,
    transitions.PROVIDER_COUNTER,
    transitions.PROVIDER_DECLINE,
    transitions.EXPIRE_CUSTOMER_OFFER,
    transitions.EXPIRE_PROVIDER_OFFER,
    transitions.REQUEST_PURCHASE,
    transitions.OFFER_ACCEPTED,
    transitions.OFFER_DECLINED,
    transitions.OFFER_EXPIRED,
    transitions.EXPIRE_PAYMENT,
    transitions.MARK_SHIPPED,
    transitions.AUTO_MARK_DELIVERED,
  ].includes(transition);
};
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
    transition
  );
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.AUTO_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.CANCEL,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PURCHASED];

export const negotitationState = states.CUSTOMER_MADE_OFFER;
