import React, { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import IconCopy from '../IconCopy/IconCopy';

import css from './CopyButton.module.css';

const CopyButton = ({ value, className }) => {
  const [copied, setCopied] = useState(false);
  const timeout = useRef(null);

  const copyToClipboard = () => {
    if (typeof window !== 'undefined') {
      window.navigator.clipboard.writeText(value);
      setCopied(true);
      timeout.current = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  useEffect(() => () => clearTimeout(timeout.current), []);

  return (
    <div className={classNames(css.root, className)} onClick={copyToClipboard}>
      {copied ? <FormattedMessage id="CopyButton.copied" /> : <IconCopy />}
    </div>
  );
};

CopyButton.defaultProps = {
  value: '',
  className: null,
};

CopyButton.propTypes = {
  value: string,
  className: string,
};

export default CopyButton;
