import React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import NewsCard from '../NewsCard/NewsCard';
import newsData from './data';
import { PrimaryButton } from '../../../../components';
import css from './NewsSections.module.css';

const data = newsData;

export default function NewsSection() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={css.root}>
      <div className={css.sectionHeader}>
        <div className={css.title}>
          <FormattedMessage id="LandingPage.newsEvents.heading" />
        </div>
        <div className={css.line} />
        <PrimaryButton className={css.shopAllBtn}>
          <FormattedMessage id="LandingPage.newsEvents.viewAllBtn" />
        </PrimaryButton>
      </div>
      <Slider {...settings} className={css.slider}>
        {data.map(item => {
          return <NewsCard key={item?.id} item={item} />;
        })}
      </Slider>
    </div>
  );
}
