import React, { useMemo } from 'react';
import { object, string } from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ResponsiveImage } from '../../../../components';
import { imageVariants } from '../../../../util/configHelpers';
import { LOVED_BRANDS_KEY, TRENDING_CATEGORIES_KEY } from '../../LandingPage';
import css from './CategoryCard.module.css';

function CategoryCard(props) {
  const { img, title, blockId, url, type } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(url);
  };

  const classes = useMemo(() => {
    const classOptions = {
      [TRENDING_CATEGORIES_KEY]: css.trendingCard,
      [LOVED_BRANDS_KEY]: css.brandCard,
    };
    return classOptions[type] || css.card;
  }, [type]);

  const imgClasses = useMemo(() => {
    const classOptions = {
      [TRENDING_CATEGORIES_KEY]: css.trendingImg,
      [LOVED_BRANDS_KEY]: css.brandImg,
    };
    return classOptions[type] || css.img;
  }, [type]);

  return (
    <div key={blockId} className={classes} onClick={handleClick}>
      <ResponsiveImage
        rootClassName={imgClasses}
        alt={title}
        image={img}
        variants={imageVariants}
      />
      <div className={css.text}>{title}</div>
    </div>
  );
}

CategoryCard.propTypes = {
  type: string.isRequired,
  img: object.isRequired,
  title: string.isRequired,
  blockId: string.isRequired,
  url: string,
};

CategoryCard.defaultProps = {
  url: '',
};

export default CategoryCard;
