import { array, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import { PrimaryButton } from '../../../../components';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';
import CategoryCard from '../CategoryCard/CategoryCard';
import css from './CategorySection.module.css';

function CategorySection({ items, title, type }) {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const onClick = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: items?.length ?? 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: items?.length ?? 0,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={css.sectionWrapper}>
      <div className={css.sectionHeader}>
        <div className={css.title}>{title}</div>
        <div className={css.line} />
        <PrimaryButton className={css.shopAllBtn} onClick={onClick}>
          <FormattedMessage id="CategorySection.shoppAllBtn" />
        </PrimaryButton>
      </div>
      <div className={css.row}>
        <Slider {...settings} className={css.slider}>
          {items?.map((item) => {
            const { blockId, image, title: titleItem, url } = item || {};
            return (
              <CategoryCard
                key={blockId}
                img={image}
                title={titleItem}
                blockId={blockId}
                url={url}
                type={type}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

CategorySection.propTypes = {
  type: string.isRequired,
  items: array.isRequired,
  title: string.isRequired,
};
export default CategorySection;
