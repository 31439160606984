import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './PromotionSection.module.css';
import { PrimaryButton } from '../../../../components';

function PromotionSection() {
  return (
    <div className={css.root}>
      <div className={css.title}>
        <FormattedMessage id="LandingPage.promotionContent" />
      </div>
      <PrimaryButton className={css.transparentBtn}>
        <FormattedMessage id="LandingPage.promotionContent.learnMore" />
      </PrimaryButton>
    </div>
  );
}

export default PromotionSection;
