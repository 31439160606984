import { bool, string } from 'prop-types';
import React from 'react';

const IconHeart = ({ selected, fill, stroke, ...props }) => {
  const pathProps = selected
    ? { fill }
    : {
        fill: 'transparent',
        stroke,
      };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M12 20a1 1 0 0 1-.437-.1C11.214 19.73 3 15.671 3 9a5 5 0 0 1 8.535-3.536l.465.465.465-.465A5 5 0 0 1 21 9c0 6.646-8.212 10.728-8.562 10.9A1 1 0 0 1 12 20z"
        strokeWidth={2}
        {...pathProps}
      />
    </svg>
  );
};

IconHeart.defaultProps = {
  selected: false,
  fill: 'red',
  stroke: 'currentColor',
};

IconHeart.propTypes = {
  selected: bool,
  fill: string,
  stroke: string,
};
export default IconHeart;
