import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const ServiceFeeMaybe = props => {
  const { marketplaceCurrency, intl } = props;

  const formattedServiceFee = formatMoney(intl, new Money(0, marketplaceCurrency));

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.serviceFee" />
      </span>
      <span className={css.itemValue}>{formattedServiceFee}</span>
    </div>
  );
};

ServiceFeeMaybe.propTypes = {
  intl: intlShape.isRequired,
  marketplaceCurrency: string,
};

export default ServiceFeeMaybe;
