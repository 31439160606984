import news1 from '../../../../assets/news_1.png';
import news2 from '../../../../assets/news_2.png';
import news3 from '../../../../assets/news_3.png';
import news4 from '../../../../assets/news_4.png';

const newsData = [
  {
    id: '111',
    image_url: news1,
    title: 'COMMING SOON',
    headline: 'Learn more about how to enter for a fun-filled weekend on us.',
    date_posted: '2023-10-10',
    topic: 'GIVEAWAY',
    newsUrl: 'http://example.com/',
  },
  {
    id: '112',
    image_url: news2,
    title: 'COMMING SOON',
    headline: 'Round up your purchase and support our partner, RMEF ',
    date_posted: '2023-10-15',
    topic: 'CONSERVANCY',
    newsUrl: 'http://example.com/',
  },
  {
    id: '113',
    image_url: news3,
    title: 'COMMING SOON',
    headline: 'Join Redspur in support of the youth artisans in the USA.',
    date_posted: '2023-10-17',
    topic: 'ARTISAN COMMUNITY SPOTLIGHT',
    newsUrl: 'http://example.com/',
  },
  {
    id: '114',
    image_url: news4,
    title: 'COMMING SOON',
    headline: 'Join Redspur in support of the youth artisans in the USA.',
    date_posted: '2023-10-17',
    topic: 'ARTISAN COMMUNITY SPOTLIGHT',
    newsUrl: 'http://example.com/',
  },
];

export default newsData;
