import { storableError } from '../../util/errors';
import { listVouchers } from '../../util/api';
import { parse } from '../../util/urlHelpers';

const VOUCHERS_PAGE_SIZE = 5;

const FETCH_MY_VOUCHERS_REQUEST = 'app/MyVouchersPage/FETCH_MY_VOUCHERS_REQUEST';
const FETCH_MY_VOUCHERS_SUCCESS = 'app/MyVouchersPage/FETCH_MY_VOUCHERS_SUCCESS';
const FETCH_MY_VOUCHERS_ERROR = 'app/MyVouchersPage/FETCH_MY_VOUCHERS_ERROR';

const initialState = {
  vouchers: [],
  fetchMyVouchersInProgress: false,
  fetchMyVouchersError: null,
  pagination: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MY_VOUCHERS_REQUEST:
      return {
        ...state,
        fetchMyVouchersInProgress: true,
        fetchMyVouchersError: null,
      };
    case FETCH_MY_VOUCHERS_SUCCESS:
      return {
        ...state,
        vouchers: payload.vouchers,
        pagination: payload.meta,
        fetchMyVouchersInProgress: false,
      };
    case FETCH_MY_VOUCHERS_ERROR:
      return {
        ...state,
        fetchMyVouchersInProgress: false,
        fetchMyVouchersError: payload.error,
      };
    default:
      return state;
  }
}

const fetchMyVouchersRequest = () => ({ type: FETCH_MY_VOUCHERS_REQUEST });
const fetchMyVouchersSuccess = response => ({
  type: FETCH_MY_VOUCHERS_SUCCESS,
  payload: response,
});
const fetchMyVouchersError = e => ({
  type: FETCH_MY_VOUCHERS_ERROR,
  payload: e,
});

export const loadData = (_, search) => async dispatch => {
  dispatch(fetchMyVouchersRequest());
  try {
    const { page = 1 } = parse(search);
    const voucherListResponse = await listVouchers({ limit: VOUCHERS_PAGE_SIZE, page });

    dispatch(fetchMyVouchersSuccess(voucherListResponse.data));
  } catch (e) {
    dispatch(fetchMyVouchersError(storableError(e)));
  }
};

export const vouchersPageSelector = state => state.MyVouchersPage;
