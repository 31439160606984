/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { getAssetsCategories } from '../../../util/data';

import { LinkedLogo, NamedLink } from '../../../components';

import googlePlayBtn from '../../../assets/googleplay_btn.png';
import appstoreBtn from '../../../assets/appstore_btn.png';
import { CATEGORIES_KEY } from '../../../containers/LandingPage/LandingPage';
import Category from './components/Category';

import IconFacebook from '../../IconFacebook/IconFacebook';
import IconLinkedin from '../../IconLinkedin/IconLinkedin';
import IconInstagram from '../../IconInstagram/IconInstagram';
import IconPinterest from '../../IconPinterest/IconPinterest';
import IconYoutube from '../../IconYoutube/IconYoutube';
import IconTiktok from '../../IconTiktok/IconTiktok';
import IconArrow from '../../IconArrow/IconArrow';

import css from './TopbarMobileMenu.module.css';
import NavigationLinks from './components/NavigationLinks';

const navigationLinksData = [
  { name: 'LandingPage', text: 'home' },
  {
    name: 'SearchPage',
    text: 'shop',
  },
  {
    name: 'NewListingPage',
    text: 'sell',
  },
  {
    name: 'SearchPage',
    text: 'trending',
  },
  {
    name: 'LandingPage',
    text: 'news',
  },
  {
    name: 'LandingPage',
    text: 'support',
  },
  {
    name: 'LandingPage',
    text: 'privacy',
  },
  {
    name: 'LandingPage',
    text: 'safety',
  },
  {
    name: 'LandingPage',
    text: 'sitemaps',
  },
  {
    name: 'LandingPage',
    text: 'cookies',
  },
];

const TopbarMobileMenu = props => {
  const { isAuthenticated, currentPage, onLogout } = props;

  const intl = useIntl();
  const assets = useSelector(state => state.hostedAssets.pageAssetsData?.landingPage);
  const { sections } = assets?.data || {};

  const categoryList = sections?.find(item => {
    return item.sectionId === CATEGORIES_KEY;
  });
  const categoryData = getAssetsCategories(categoryList);

  const authenticatedNavigationLinks = isAuthenticated ? (
    <div className={css.authenNavigationLinks}>
      <NamedLink name="ProfileSettingsPage" className={css.authenLink}>
        <FormattedMessage id="TopbarMobileMenu.myProfile" />
        <IconArrow />
      </NamedLink>
      <NamedLink name="ManageListingsPage" className={css.authenLink}>
        <FormattedMessage id="TopbarMobileMenu.listings" />
        <IconArrow />
      </NamedLink>
      <NamedLink name="AccountSettingsPage" className={css.authenLink}>
        <FormattedMessage id="TopbarMobileMenu.accountSettings" />
        <IconArrow />
      </NamedLink>
      <NamedLink name="LandingPage" className={css.authenLink}>
        <FormattedMessage id="TopbarMobileMenu.purchases" />
        <IconArrow />
      </NamedLink>
      <NamedLink name="LandingPage" className={classNames(css.authenLink, css.boderNone)}>
        <FormattedMessage id="TopbarMobileMenu.supportCenter" />
        <IconArrow />
      </NamedLink>
    </div>
  ) : null;

  const socials = isAuthenticated ? (
    <div className={css.socials}>
      <IconLinkedin />
      <IconInstagram />
      <IconFacebook />
      <IconPinterest />
      <IconYoutube />
      <IconTiktok />
    </div>
  ) : null;

  return (
    <div className={css.root}>
      <LinkedLogo
        format="mobile"
        className={css.menuLogoMobile}
        alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
      />

      <div className={css.content}>
        {/* <NamedLink className={css.sellItemBtn} name="NewListingPage">
          <FormattedMessage id="TopbarDesktop.createListing" />
        </NamedLink> */}
        <div>
          <div className={css.categories}>
            {categoryData?.items.map(item => {
              const { blockId, image, title: titleItem, url } = item || {};
              return (
                <Category key={blockId} img={image} title={titleItem} blockId={blockId} url={url} />
              );
            })}
          </div>
        </div>

        {authenticatedNavigationLinks}
        <div className={css.explore}>
          <FormattedMessage id="TopbarMobileMenu.explore" />
        </div>
        <NavigationLinks data={navigationLinksData} currentPage={currentPage} />
        <div className={css.getAppWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.heading}>
              <FormattedMessage id="LandingPage.GetAppSection.heading" />
            </div>
            <div className={css.appContent}>
              <FormattedMessage id="LandingPage.GetAppSection.content" />
            </div>
            <div className={css.buttonWrapper}>
              <div className={css.btn}>
                <img src={appstoreBtn} alt="appstore" className={css.img} />
              </div>
              <div className={css.btn}>
                <img src={googlePlayBtn} alt="googleplay" className={css.img} />
              </div>
            </div>
          </div>
          <div className={css.images}>
            <div className={css.desktopImg} />
            <div className={css.mobileImg} />
          </div>
        </div>
      </div>
      <div className={css.footer}>
        {!isAuthenticated && (
          <>
            <NamedLink className={css.signupBtn} name="SignupPage">
              <FormattedMessage id="TopbarMobileMenu.signupLink" />
            </NamedLink>
            <NamedLink className={css.loginBtn} name="LoginPage">
              <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
          </>
        )}
        {isAuthenticated && (
          <div className={css.loginBtn} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
          </div>
        )}
        {socials}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentPage: null };

const { bool, func, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentPage: string,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
