import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics';
import isEqual from 'lodash/isEqual';

export const trackEvent = (eventName, eventProperties) => {
  logEvent(getAnalytics(), eventName, eventProperties);
};

export const setAnalyticsUserProperties = ({ userId, ...userProperties }) => {
  setUserId(getAnalytics(), userId, {
    global: true,
  });
  setUserProperties(getAnalytics(), userProperties, { global: true });
};

function currentUserSelect(state) {
  return state.user.currentUser;
}
let currentUser;
export const currentUserChangedSubscription = store => {
  let previousUser = currentUser;
  currentUser = currentUserSelect(store.getState());
  if (!isEqual(currentUser, previousUser) && currentUser) {
    const { id, attributes } = currentUser;
    const { email, profile } = attributes;
    const { firstName, lastName } = profile;
    setAnalyticsUserProperties({
      userId: id,
      email,
      firstName,
      lastName,
    });
    previousUser = currentUser;
  }
};

export const EVENT_NAMES = {
  pageView: 'page_view',
  selectAProductFromAList: 'select_a_product_from_a_list',
  viewProductDetails: 'view_product_details',
  searchForAKeyword: 'search_for_a_keyword',
  initiateTheCheckoutProcess: 'initiate_the_checkout_process',
  makePurchases: 'make_purchases',
  dispute: 'dispute',
};

export const trackSelectAProductFromAList = ({ productId, productTitle }) => {
  trackEvent(EVENT_NAMES.selectAProductFromAList, {
    product_id: productId,
    product_title: productTitle,
  });
};

export const trackViewProductDetails = ({ productId, productTitle }) => {
  trackEvent(EVENT_NAMES.viewProductDetails, {
    product_id: productId,
    product_title: productTitle,
  });
};

export const trackSearchForAKeyword = ({ keyword }) => {
  trackEvent(EVENT_NAMES.searchForAKeyword, {
    keyword,
  });
};

export const trackInitiateTheCheckoutProcess = ({ productId, productTitle }) => {
  trackEvent(EVENT_NAMES.initiateTheCheckoutProcess, {
    product_id: productId,
    product_title: productTitle,
  });
};

export const trackMakePurchases = ({ productId, productTitle }) => {
  trackEvent(EVENT_NAMES.makePurchases, {
    product_id: productId,
    product_title: productTitle,
  });
};

export const trackDispute = ({ transactionId }) => {
  trackEvent(EVENT_NAMES.dispute, {
    transaction_id: transactionId,
  });
};
