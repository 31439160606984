// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import Decimal from 'decimal.js';
import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const handleApiServerResponse = res => {
  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

  if (res.status >= 400) {
    return res.json().then(data => {
      let e = new Error();
      e = Object.assign(e, data);

      throw e;
    });
  }
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  }
  if (contentType === 'application/json') {
    return res.json();
  }
  return res.text();
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(handleApiServerResponse);
};

const get = path => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return window.fetch(url, options).then(handleApiServerResponse);
};

const put = (path, body = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(handleApiServerResponse);
};

const deleteMethod = path => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'DELETE',
    credentials: 'include',
  };
  return window.fetch(url, options).then(handleApiServerResponse);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/transactions/initiate', body);
};

export const confirmPrivileged = body => {
  return post('/api/transactions/confirm', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

// Send verification code as sms to the user's phone number
export const sendVerificationCode = body => {
  return post('/api/auth/sms/send', body);
};

export const verifyCode = body => {
  return post('/api/auth/sms/verify', body);
};

export const toggleVacationMode = () => {
  return post('/api/user/toggle-vacation-mode');
};

export const like = (listingId, queryPrams) => {
  const searchparams = new URLSearchParams({
    ...queryPrams,
    listingId,
  });
  return put(`/api/user/like?${searchparams}`, { listingId });
};

export const unlike = (listingId, queryPrams) => {
  const searchparams = new URLSearchParams({
    ...queryPrams,
    listingId,
  });
  return deleteMethod(`/api/user/like?${searchparams}`);
};

export const getShippingRates = body => {
  return post('/api/shippo/shipments', body);
};

export const getShippingLabel = labelId => {
  return get(`/api/shippo/labels/${labelId}`);
};

export const transitionNegotiate = body => {
  return post('/api/transactions/transition-negotiate', body);
};

export const walletCurrentYearEarning = () => get('/api/wallet/current-year-earning');

export const getAnalyticsData = () => get('/api/analytics/get-analytics-data');
export const getDonationsInformation = () => get('/api/donation/donations-information');
export const updateDonationItem = body => post('/api/donation/donations-tracking', body);
export const queryDonateHistory = () => get(`/api/donation/query-donations-tracking`);

export const createFakeLineItems = body => {
  return post('/api/shippo/lineItems', body);
};

export const listVouchers = ({ limit, page }) =>
  get(`/api/vouchers/list?limit=${limit}&page=${page}`);

export const unsubscribe = body => {
  return post('/api/user/notifications/unsubscribe', body);
};

export const deleteUserAccount = userId => {
  return post('/api/delete-account', { userId });
};
