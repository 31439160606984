import { getAnalyticsData } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
const FETCH_ANALYTICS_REQUEST = 'app/AnalyticsPage/FETCH_ANALYTICS_REQUEST';
const FETCH_ANALYTICS_SUCCESS = 'app/AnalyticsPage/FETCH_ANALYTICS_SUCCESS';
const FETCH_ANALYTICS_ERROR = 'app/AnalyticsPage/FETCH_ANALYTICS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchAnalyticsError: null,
  fetchAnalyticsInProgress: false,
  analyticsData: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ANALYTICS_REQUEST:
      return {
        ...state,
        fetchAnalyticsInProgress: true,
        fetchAnalyticsError: null,
      };
    case FETCH_ANALYTICS_SUCCESS:
      return { ...state, fetchAnalyticsInProgress: false, analyticsData: payload };
    case FETCH_ANALYTICS_ERROR:
      return { ...state, fetchAnalyticsInProgress: false, fetchAnalyticsError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchAnalyticsRequest = () => ({ type: FETCH_ANALYTICS_REQUEST });
const fetchAnalyticsSuccess = data => ({ type: FETCH_ANALYTICS_SUCCESS, payload: data });
const fetchAnalyticsError = error => ({
  type: FETCH_ANALYTICS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const fetchAnalytics = () => async dispatch => {
  try {
    dispatch(fetchAnalyticsRequest());
    const response = await getAnalyticsData();
    return dispatch(fetchAnalyticsSuccess(response.data));
  } catch (e) {
    return dispatch(fetchAnalyticsError(storableError(e)));
  }
};

export const loadData = () => dispatch => dispatch(fetchAnalytics());

// ================= Selectors ================= //
export const fetchAnalyticsInProgressSelector = state =>
  state.AnalyticsPage.fetchAnalyticsInProgress;
export const fetchAnalyticsErrorSelector = state => state.AnalyticsPage.fetchAnalyticsError;
export const analyticsDataSelector = state => state.AnalyticsPage.analyticsData;
