import React from 'react';

const IconCopy = props => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8_2)" fill="#000">
        <path d="M700 250v450H250V250h450zm0-50H250a49.997 49.997 0 00-50 50v450a49.997 49.997 0 0050 50h450a49.997 49.997 0 0050-50V250a49.997 49.997 0 00-50-50z" />
        <path d="M100 450H50V100a50 50 0 0150-50h350v50H100v350z" />
      </g>
      <defs>
        <clipPath id="clip0_8_2">
          <path fill="#fff" d="M0 0H800V800H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCopy;
