export const categoryOptions = [
  {
    option: 'women',
    label: 'Women',
  },
  {
    option: 'men',
    label: 'Men',
  },
  {
    option: 'boys',
    label: 'Boys',
  },
  {
    option: 'girls',
    label: 'Girls',
  },
];

export const subCategoryOptions = {
  women: [
    {
      option: 'accessories',
      label: 'Accessories',
    },
    {
      option: 'bags',
      label: 'Bags',
    },
    {
      option: 'clothing',
      label: 'Clothing',
    },
    {
      option: 'jewelry',
      label: 'Jewelry',
    },
    {
      option: 'shoes',
      label: 'Shoes',
    },
  ],
  men: [
    {
      option: 'accessories',
      label: 'Accessories',
    },
    {
      option: 'bags',
      label: 'Bags',
    },
    {
      option: 'clothing',
      label: 'Clothing',
    },
    {
      option: 'shoes',
      label: 'Shoes',
    },
  ],
  boys: [
    {
      option: 'accessories',
      label: 'Accessories',
    },
    {
      option: 'clothing',
      label: 'Clothing',
    },
    {
      option: 'shoes',
      label: 'Shoes',
    },
  ],
  girls: [
    {
      option: 'accessories',
      label: 'Accessories',
    },
    {
      option: 'clothing',
      label: 'Clothing',
    },
    {
      option: 'shoes',
      label: 'Shoes',
    },
  ],
};

export const typeOptions = {
  women: {
    accessories: [
      { option: 'belts', label: 'Belts' },
      { option: 'faceMasks', label: 'Face Masks' },
      { option: 'glasses', label: 'Glasses' },
      { option: 'glovesMittens', label: 'Gloves & Mittens' },
      { option: 'hairAccessories', label: 'Hair accessories' },
      { option: 'hats', label: 'Hats' },
      { option: 'hosierySocks', label: 'Hosiery & Socks' },
      { option: 'keyCardHolders', label: 'Key & Card Holders' },
      { option: 'laptopCases', label: 'Laptop Cases' },
      { option: 'phoneCases', label: 'Phone Cases' },
      { option: 'scarvesWraps', label: 'Scarves & Wraps' },
      { option: 'sunglasses', label: 'Sunglasses' },
      { option: 'tabletCases', label: 'Tablet Cases' },
      { option: 'umbrellas', label: 'Umbrellas' },
      { option: 'watches', label: 'Watches' },
      { option: 'other', label: 'Other' },
    ],
    bags: [
      { option: 'babyBags', label: 'Baby Bags' },
      { option: 'backpacks', label: 'Backpacks' },
      { option: 'clutchesWristlets', label: 'Clutches & Wristlets' },
      { option: 'cosmeticBags', label: 'Cosmetic Bags' },
      { option: 'crossBodyBags', label: 'Crossbody Bags' },
      { option: 'hobos', label: 'Hobos' },
      { option: 'laptopBags', label: 'Laptop Bags' },
      { option: 'miniBags', label: 'Mini Bags' },
      { option: 'satchels', label: 'Satchels' },
      { option: 'shoulderBags', label: 'Shoulder Bags' },
      { option: 'totes', label: 'Totes' },
      { option: 'travelBags', label: 'Travel Bags' },
      { option: 'wallets', label: 'Wallets' },
      { option: 'other', label: 'Other' },
    ],
    clothing: [
      { option: 'athleticApparel', label: 'Athletic Apparel' },
      { option: 'bibsCoveralls', label: 'Bibs & Coveralls' },
      { option: 'dresses', label: 'Dresses' },
      { option: 'sleepwear', label: 'Sleepwear' },
      { option: 'jacketsCoats', label: 'Jackets & Coats' },
      { option: 'suitsBlazers', label: 'Suits & Blazers' },
      { option: 'jeans', label: 'Jeans' },
      { option: 'pantsJumpsuits', label: 'Pants & Jumpsuits' },
      { option: 'shorts', label: 'Shorts' },
      { option: 'skirts', label: 'Skirts' },
      { option: 'sweaters', label: 'Sweaters' },
      { option: 'swim', label: 'Swim' },
      { option: 'topsBlouses', label: 'Tops & Blouses' },
      { option: 'other', label: 'Other' },
    ],
    jewelry: [
      { option: 'bracelets', label: 'Bracelets' },
      { option: 'pins', label: 'Pins' },
      { option: 'earrings', label: 'Earrings' },
      { option: 'necklaces', label: 'Necklaces' },
      { option: 'rings', label: 'Rings' },
      { option: 'other', label: 'Other' },
    ],
    shoes: [
      { option: 'ankleBootsBooties', label: 'Ankle Boots & Booties' },
      { option: 'athleticShoes', label: 'Athletic Shoes' },
      { option: 'boots', label: 'Boots' },
      { option: 'flatsLoafers', label: 'Flats & Loafers' },
      { option: 'heels', label: 'Heels' },
      { option: 'platforms', label: 'Platforms' },
      { option: 'sandals', label: 'Sandals' },
      { option: 'sneakers', label: 'Sneakers' },
      { option: 'wedges', label: 'Wedges' },
      { option: 'winterRainBoots', label: 'Winter & Rain Boots' },
      { option: 'workSafety', label: 'Work & Safety' },
      { option: 'other', label: 'Other' },
    ],
  },
  men: {
    accessories: [
      { option: 'belts', label: 'Belts' },
      { option: 'cuff-links', label: 'Cuff Links' },
      { option: 'face-masks', label: 'Face Masks' },
      { option: 'gloves', label: 'Gloves' },
      { option: 'hats', label: 'Hats' },
      { option: 'jewelry', label: 'Jewelry' },
      { option: 'key-card-holders', label: 'Key & Card Holders' },
      { option: 'money-clips', label: 'Money clips' },
      { option: 'phone-cases', label: 'Phone Cases' },
      { option: 'scarves', label: 'Scarves' },
      { option: 'sunglasses', label: 'Sunglasses' },
      { option: 'ties-neckwear', label: 'Ties & Neckwear' },
      { option: 'watches', label: 'Watches' },
      { option: 'other', label: 'Other' },
    ],
    bags: [
      { option: 'backpacks', label: 'Backpacks' },
      { option: 'briefcases', label: 'Briefcases' },
      { option: 'duffle-bags', label: 'Duffle Bags' },
      { option: 'laptop-bags', label: 'Laptop Bags' },
      { option: 'luggage-travel-bags', label: 'Luggage & Travel Bags' },
      { option: 'messenger-bags', label: 'Messenger Bags' },
      { option: 'wallets', label: 'Wallets' },
      { option: 'other', label: 'Other' },
    ],
    clothing: [
      { option: 'athletic-apparel', label: 'Athletic Apparel' },
      { option: 'bibs-coveralls', label: 'Bibs & Coveralls' },
      { option: 'jackets-coats', label: 'Jackets & Coats' },
      { option: 'jeans', label: 'Jeans' },
      { option: 'pants', label: 'Pants' },
      { option: 'shirts', label: 'Shirts' },
      { option: 'shorts', label: 'Shorts' },
      { option: 'blazers', label: 'Blazers' },
      { option: 'sweaters', label: 'Sweaters' },
      { option: 'swim', label: 'Swim' },
      { option: 'socks', label: 'Socks' },
      { option: 'other', label: 'Other' },
    ],
    shoes: [
      { option: 'athletic-shoes', label: 'Athletic Shoes' },
      { option: 'boat-shoes', label: 'Boat Shoes' },
      { option: 'boots', label: 'Boots' },
      { option: 'loafers-slip-ons', label: 'Loafers & Slip Ons' },
      { option: 'oxfords', label: 'Oxfords' },
      { option: 'rain-snow-boots', label: 'Rain & Snow Boots' },
      { option: 'sandals-flip-flops', label: 'Sandals & Flip Flops' },
      { option: 'sneakers', label: 'Sneakers' },
      { option: 'work-safety', label: 'Work & Safety' },
      { option: 'other', label: 'Other' },
    ],
  },
  girls: {
    accessories: [
      { option: 'bags-backpacks', label: 'Bags & Backpacks' },
      { option: 'belts', label: 'Belts' },
      { option: 'jewelry', label: 'Jewelry' },
      { option: 'gloves-mittens', label: 'Gloves & Mittens' },
      { option: 'hair-accessories', label: 'Hair Accessories' },
      { option: 'hosiery-socks', label: 'Hosiery & Socks' },
      { option: 'scarves', label: 'Scarves' },
      { option: 'sunglasses', label: 'Sunglasses' },
      { option: 'other', label: 'Other' },
    ],
    clothing: [
      { option: 'bibs-coveralls', label: 'Bibs & Coveralls' },
      { option: 'bottoms', label: 'Bottoms' },
      { option: 'coats-jackets', label: 'Coats & Jackets' },
      { option: 'dresses', label: 'Dresses' },
      { option: 'one-pieces', label: 'One-Pieces' },
      { option: 'swimwear', label: 'Swimwear' },
      { option: 'tops-t-shirts', label: 'Tops & T-shirts' },
      { option: 'other', label: 'Other' },
    ],
    shoes: [
      { option: 'athletic-shoes', label: 'Athletic Shoes' },
      { option: 'boots', label: 'Boots' },
      { option: 'casual-shoes', label: 'Casual Shoes' },
      { option: 'dress-shoes', label: 'Dress Shoes' },
      { option: 'rain-snow-boots', label: 'Rain & Snow Boots' },
      { option: 'sneakers', label: 'Sneakers' },
      { option: 'sandals', label: 'Sandals' },
      { option: 'other', label: 'Other' },
    ],
  },
  boys: {
    accessories: [
      { option: 'bags-backpacks', label: 'Bags & Backpacks' },
      { option: 'belts', label: 'Belts' },
      { option: 'gloves-mittens', label: 'Gloves & Mittens' },
      { option: 'hats', label: 'Hats' },
      { option: 'socks', label: 'Socks' },
      { option: 'sunglasses', label: 'Sunglasses' },
      { option: 'ties-neckwear', label: 'Ties & Neckwear' },
      { option: 'other', label: 'Other' },
    ],
    clothing: [
      { option: 'bibs-coveralls', label: 'Bibs & Coveralls' },
      { option: 'bottoms', label: 'Bottoms' },
      { option: 'coats-jackets', label: 'Coats & Jackets' },
      { option: 'one-pieces', label: 'One-Pieces' },
      { option: 'swimwear', label: 'Swimwear' },
      { option: 'tops-t-shirts', label: 'Tops & T-shirts' },
      { option: 'other', label: 'Other' },
    ],
    shoes: [
      { option: 'athletic-shoes', label: 'Athletic Shoes' },
      { option: 'boots', label: 'Boots' },
      { option: 'casual-shoes', label: 'Casual Shoes' },
      { option: 'dress-shoes', label: 'Dress Shoes' },
      { option: 'rain-snow-boots', label: 'Rain & Snow Boots' },
      { option: 'sneakers', label: 'Sneakers' },
      { option: 'sandals', label: 'Sandals' },
      { option: 'other', label: 'Other' },
    ],
  },
};

// Size groups: WOMEN

const womenFashion = {
  option: 'womenSizeGroup',
  label: "Women's Fashion",
};

const womenFashionSizes = [
  { option: 'xxs', label: 'XXS (00)' },
  { option: 'xs', label: 'XS (0-2)' },
  { option: 's', label: 'S (4-6)' },
  { option: 'm', label: 'M (8-10)' },
  { option: 'l', label: 'L (12-14)' },
  { option: 'xl', label: 'XL (16-18)' },
  { option: '1x', label: '1X (16-18)' },
  { option: '2x', label: '2X (20-22)' },
  { option: '3x', label: '3X (24-26)' },
  { option: '4x', label: '4X (28-30)' },
  { option: '5x', label: '5X (32-34)' },
  { option: 'one-size', label: 'One Size' },
  { option: 'petite-xxs', label: 'Petite XXS (00)' },
  { option: 'petite-xs', label: 'Petite XS (0-2)' },
  { option: 'petite-s', label: 'Petite S (0-2)' },
  { option: 'petite-m', label: 'Petite M (8-10)' },
  { option: 'petite-l', label: 'Petite L (12-14)' },
  { option: 'petite-xl', label: 'Petite XL (16-18)' },
  { option: 'petite-1x', label: 'Petite 1X (16-18)' },
  { option: 'petite-2x', label: 'Petite 2X (20-22)' },
  { option: 'petite-3x', label: 'Petite 3X (24-26)' },
  { option: 'petite-4x', label: 'Petite 4X (28-30)' },
  { option: 'petite-5x', label: 'Petite 5X (32-34)' },
  { option: 'maternity-xxs', label: 'Maternity XXS (00)' },
  { option: 'maternity-xs', label: 'Maternity XS (0-2)' },
  { option: 'maternity-s', label: 'Maternity S (4-6)' },
  { option: 'maternity-m', label: 'Maternity M (8-10)' },
  { option: 'maternity-l', label: 'Maternity L (12-14)' },
  { option: 'maternity-xl', label: 'Maternity XL (16-18)' },
  { option: 'maternity-1x', label: 'Maternity 1X (16-18)' },
  { option: 'maternity-2x', label: 'Maternity 2X (20-22)' },
  { option: 'maternity-3x', label: 'Maternity 3X (24-26)' },
  { option: 'maternity-4x', label: 'Maternity 4X (28-30)' },
  { option: 'maternity-5x', label: 'Maternity 5X (32-34)' },
  { option: 'tall-xxs', label: 'Tall XXS (00)' },
  { option: 'tall-xs', label: 'Tall XS (0-2)' },
  { option: 'tall-s', label: 'Tall S (0-2)' },
  { option: 'tall-m', label: 'Tall M (8-10)' },
  { option: 'tall-l', label: 'Tall L (12-14)' },
  { option: 'tall-xl', label: 'Tall XL (16-18)' },
  { option: 'tall-1x', label: 'Tall 1X (16-18)' },
  { option: 'tall-2x', label: 'Tall 2X (20-22)' },
  { option: 'tall-3x', label: 'Tall 3X (24-26)' },
  { option: 'tall-4x', label: 'Tall 4X (28-30)' },
  { option: 'tall-5x', label: 'Tall 5X (32-34)' },
  { option: 'juniors-xs', label: 'Juniors XS (0-1)' },
  { option: 'juniors-s', label: 'Juniors S (3-5)' },
  { option: 'juniors-m', label: 'Juniors M (7-9)' },
  { option: 'juniors-l', label: 'Juniors L (11-13)' },
  { option: 'juniors-xl', label: 'Juniors XL (15-17)' },
];

const adultPant = {
  option: 'adultPantSize',
  label: 'Adult Pant Size',
};

const adultPantSizes = [
  { option: '23-in', label: '23 in.' },
  { option: '24-in', label: '24 in.' },
  { option: '25-in', label: '25 in.' },
  { option: '26-in', label: '26 in.' },
  { option: '27-in', label: '27 in.' },
  { option: '28-in', label: '28 in.' },
  { option: '29-in', label: '29 in.' },
  { option: '30-in', label: '30 in.' },
  { option: '31-in', label: '31 in.' },
  { option: '32-in', label: '32 in.' },
  { option: '33-in', label: '33 in.' },
  { option: '34-in', label: '34 in.' },
  { option: '35-in', label: '35 in.' },
  { option: '36-in', label: '36 in.' },
  { option: '37-in', label: '37 in.' },
  { option: '38-in', label: '38 in.' },
  { option: '39-in', label: '39 in.' },
  { option: '40-in', label: '40 in.' },
  { option: '41-in', label: '41 in.' },
  { option: '42-in', label: '42 in.' },
  { option: '43-in', label: '43 in.' },
  { option: '44-in', label: '44 in.' },
  { option: '45-in', label: '45 in.' },
  { option: '46-in', label: '46 in.' },
  { option: '47-in', label: '47 in.' },
  { option: '48-in', label: '48 in.' },
  { option: '49-in', label: '49 in.' },
  { option: '50-in', label: '50 in.' },
  { option: '51-in', label: '51 in.' },
  { option: '52-in', label: '52 in.' },
  { option: '53-in', label: '53 in.' },
  { option: '54-in', label: '54 in.' },
  { option: '55-in', label: '55 in.' },
  { option: '56-in', label: '56 in.' },
  { option: '57-in', label: '57 in.' },
  { option: '58-in', label: '58+ in.' },
];

const womenShoes = {
  option: 'womenShoes',
  label: "Women's Shoes",
};

const womenShoesSizes = [
  { option: '4', label: '4 (34.5)' },
  { option: '4-5', label: '4.5 (35)' },
  { option: '5', label: '5 (35.5)' },
  { option: '5-5', label: '5.5 (36)' },
  { option: '6', label: '6 (36.5)' },
  { option: '6-5', label: '6.5 (37)' },
  { option: '7', label: '7 (37.5)' },
  { option: '7-5', label: '7.5 (38)' },
  { option: '8', label: '8 (38.5)' },
  { option: '8-5', label: '8.5 (39)' },
  { option: '9', label: '9 (39.5)' },
  { option: '9-5', label: '9.5 (40)' },
  { option: '10', label: '10 (40.5)' },
  { option: '10-5', label: '10.5 (41)' },
  { option: '11', label: '11 (41.5)' },
  { option: '11-5', label: '11.5 (42)' },
  { option: '12', label: '12 (42.5)' },
  { option: '12-5', label: '12.5 (43)' },
  { option: '13', label: '13 (43.5)' },
  { option: '13-5', label: '13.5 (44)' },
  { option: '14-up', label: '14 & Up (44.5)' },
];

const standardSize = {
  option: 'standardSize',
  label: 'Standard Size',
};

const standardSizes = [
  { option: 'xxs', label: 'XXS (00)' },
  { option: 'xs', label: 'XS (0-2)' },
  { option: 's', label: 'S (4-6)' },
  { option: 'm', label: 'M (8-10)' },
  { option: 'l', label: 'L (12-14)' },
  { option: 'xl', label: 'XL (16-18)' },
  { option: '2xl', label: '2XL (20-22)' },
  { option: '3xl', label: '3XL (24-26)' },
  { option: '4xl', label: '4XL (28-30)' },
  { option: '5xl', label: '5XL (32-34)' },
  { option: 'one-size', label: 'One Size' },
];

// Size groups: MEN

const menFashion = {
  option: 'menFashion',
  label: "Men's Fashion",
};

const mensFashionSizes = [
  { option: 'xs', label: 'XS (30-32)' },
  { option: 's', label: 'S (32-34)' },
  { option: 'm', label: 'M (38-40)' },
  { option: 'l', label: 'L (42-44)' },
  { option: 'xl', label: 'XL (46-48)' },
  { option: 'xxl', label: 'XXL (50-52)' },
  { option: '3x', label: '3X (54-56)' },
  { option: '4x', label: '4X (58-60)' },
  { option: '5x', label: '5X (62-64)' },
  { option: 'one-size', label: 'One Size' },
  { option: 'tall-m', label: 'Tall M' },
  { option: 'tall-l', label: 'Tall L' },
  { option: 'tall-xl', label: 'Tall XL' },
  { option: 'tall-1x', label: 'Tall 1X' },
  { option: 'tall-2x', label: 'Tall 2X' },
  { option: 'tall-3x', label: 'Tall 3X' },
  { option: 'tall-4x-plus', label: 'Tall 4X+' },
  { option: 'big-1x', label: 'Big 1X' },
  { option: 'big-2x', label: 'Big 2X' },
  { option: 'big-3x', label: 'Big 3X' },
  { option: 'big-4x-plus', label: 'Big 4X+' },
];

const menSuitBlazerSizeGroup = {
  option: 'menSuitBlazerSize',
  label: "Men's Suit Blazer Size",
};

const mensSuitBlazerSizes = [
  { option: '36s', label: '36S' },
  { option: '38s', label: '38S' },
  { option: '40s', label: '40S' },
  { option: '42s', label: '42S' },
  { option: '44s', label: '44S' },
  { option: '46s', label: '46S' },
  { option: '48s', label: '48S' },
  { option: '50s', label: '50S' },
  { option: '52s', label: '52S' },
  { option: '54s', label: '54S' },
  { option: '56s', label: '56S' },
  { option: '34r', label: '34R' },
  { option: '35r', label: '35R' },
  { option: '36r', label: '36R' },
  { option: '38r', label: '38R' },
  { option: '40r', label: '40R' },
  { option: '42r', label: '42R' },
  { option: '44r', label: '44R' },
  { option: '46r', label: '46R' },
  { option: '48r', label: '48R' },
  { option: '50r', label: '50R' },
  { option: '52r', label: '52R' },
  { option: '54r', label: '54R' },
  { option: '56r', label: '56R' },
  { option: '38l', label: '38L' },
  { option: '40l', label: '40L' },
  { option: '42l', label: '42L' },
  { option: '44l', label: '44L' },
  { option: '46l', label: '46L' },
  { option: '48l', label: '48L' },
  { option: '50l', label: '50L' },
  { option: '52l', label: '52L' },
  { option: '54l', label: '54L' },
  { option: '56l', label: '56L' },
];

const menPantSizeGroup = {
  option: 'menPantSize',
  label: 'Men Pant Size',
};

const menPantSizes = [
  { option: '26-x-28', label: '26 X 28' },
  { option: '26-x-30', label: '26 X 30' },
  { option: '26-x-32', label: '26 X 32' },
  { option: '27-x-28', label: '27 X 28' },
  { option: '27-x-30', label: '27 X 30' },
  { option: '27-x-32', label: '27 X 32' },
  { option: '27-x-34', label: '27 X 34' },
  { option: '27-x-36', label: '27 X 36' },
  { option: '27-x-38', label: '27 X 38' },
  { option: '28-x-28', label: '28 X 28' },
  { option: '28-x-30', label: '28 X 30' },
  { option: '28-x-32', label: '28 X 32' },
  { option: '28-x-34', label: '28 X 34' },
  { option: '28-x-36', label: '28 X 36' },
  { option: '28-x-38', label: '28 X 38' },
  { option: '29-x-30', label: '29 X 30' },
  { option: '29-x-32', label: '29 X 32' },
  { option: '29-x-34', label: '29 X 34' },
  { option: '29-x-36', label: '29 X 36' },
  { option: '29-x-38', label: '29 X 38' },
  { option: '30-x-30', label: '30 X 30' },
  { option: '30-x-32', label: '30 X 32' },
  { option: '30-x-34', label: '30 X 34' },
  { option: '30-x-36', label: '30 X 36' },
  { option: '30-x-38', label: '30 X 38' },
  { option: '31-x-30', label: '31 X 30' },
  { option: '31-x-32', label: '31 X 32' },
  { option: '31-x-34', label: '31 X 34' },
  { option: '31-x-36', label: '31 X 36' },
  { option: '31-x-38', label: '31 X 38' },
  { option: '32-x-30', label: '32 X 30' },
  { option: '32-x-32', label: '32 X 32' },
  { option: '32-x-34', label: '32 X 34' },
  { option: '32-x-36', label: '32 X 36' },
  { option: '32-x-38', label: '32 X 38' },
  { option: '33-x-30', label: '33 X 30' },
  { option: '33-x-32', label: '33 X 32' },
  { option: '33-x-34', label: '33 X 34' },
  { option: '33-x-36', label: '33 X 36' },
  { option: '33-x-38', label: '33 X 38' },
  { option: '34-x-30', label: '34 X 30' },
  { option: '34-x-32', label: '34 X 32' },
  { option: '34-x-34', label: '34 X 34' },
  { option: '34-x-36', label: '34 X 36' },
  { option: '34-x-38', label: '34 X 38' },
  { option: '35-x-30', label: '35 X 30' },
  { option: '35-x-32', label: '35 X 32' },
  { option: '35-x-34', label: '35 X 34' },
  { option: '35-x-36', label: '35 X 36' },
  { option: '35-x-38', label: '35 X 38' },
  { option: '36-x-30', label: '36 X 30' },
  { option: '36-x-32', label: '36 X 32' },
  { option: '36-x-34', label: '36 X 34' },
  { option: '36-x-36', label: '36 X 36' },
  { option: '36-x-38', label: '36 X 38' },
  { option: '38-x-30', label: '38 X 30' },
  { option: '38-x-32', label: '38 X 32' },
  { option: '38-x-34', label: '38 X 34' },
  { option: '38-x-36', label: '38 X 36' },
  { option: '38-x-38', label: '38 X 38' },
  { option: '40-x-30', label: '40 X 30' },
  { option: '40-x-32', label: '40 X 32' },
  { option: '40-x-34', label: '40 X 34' },
  { option: '40-x-36', label: '40 X 36' },
  { option: '40-x-38', label: '40 X 38' },
  { option: '42-x-30', label: '42 X 30' },
  { option: '42-x-32', label: '42 X 32' },
  { option: '42-x-34', label: '42 X 34' },
  { option: '42-x-36', label: '42 X 36' },
  { option: '42-x-38', label: '42 X 38' },
  { option: '44-x-30', label: '44 X 30' },
  { option: '44-x-32', label: '44 X 32' },
  { option: '44-x-34', label: '44 X 34' },
  { option: '44-x-36', label: '44 X 36' },
  { option: '44-x-38', label: '44 X 38' },
  { option: '46-x-30', label: '46 X 30' },
  { option: '46-x-32', label: '46 X 32' },
  { option: '46-x-34', label: '46 X 34' },
  { option: '46-x-36', label: '46 X 36' },
  { option: '46-x-38', label: '46 X 38' },
  { option: '48-x-30', label: '48 X 30' },
  { option: '48-x-32', label: '48 X 32' },
  { option: '48-x-34', label: '48 X 34' },
  { option: '48-x-36', label: '48 X 36' },
  { option: '48-x-38', label: '48 X 38' },
  { option: '50-x-30', label: '50 X 30' },
  { option: '50-x-32', label: '50 X 32' },
  { option: '50-x-34', label: '50 X 34' },
  { option: '50-x-36', label: '50 X 36' },
  { option: '50-x-38', label: '50 X 38' },
  { option: '52-x-30', label: '52 X 30' },
  { option: '52-x-32', label: '52 X 32' },
  { option: '52-x-34', label: '52 X 34' },
  { option: '52-x-36', label: '52 X 36' },
  { option: '52-x-38', label: '52 X 38' },
  { option: '54-x-30', label: '54 X 30' },
  { option: '54-x-32', label: '54 X 32' },
  { option: '54-x-34', label: '54 X 34' },
  { option: '54-x-36', label: '54 X 36' },
  { option: '54-x-38', label: '54 X 38' },
  { option: '56-x-30', label: '56 X 30' },
  { option: '56-x-32', label: '56 X 32' },
  { option: '56-x-34', label: '56 X 34' },
  { option: '56-x-36', label: '56 X 36' },
  { option: '56-x-38', label: '56 X 38' },
  { option: '58-x-30', label: '58 X 30' },
  { option: '58-x-32', label: '58 X 32' },
  { option: '58-x-34', label: '58 X 34' },
  { option: '58-x-36', label: '58 X 36' },
  { option: '58-x-38', label: '58 X 38' },
  { option: '60-x-30', label: '60 X 30' },
  { option: '60-x-32', label: '60 X 32' },
  { option: '60-x-34', label: '60 X 34' },
  { option: '60-x-36', label: '60 X 36' },
  { option: '60-x-38', label: '60 X 38' },
];

const menShoesSize = {
  option: 'menShoesSize',
  label: "Men's Shoe Size",
};

const mensShoesSizes = [
  { option: '5', label: '5 (38)' },
  { option: '5-5', label: '5.5 (38.5)' },
  { option: '6', label: '6 (39)' },
  { option: '6-5', label: '6.5 (39.5)' },
  { option: '7', label: '7 (40)' },
  { option: '7-5', label: '7.5 (40.5)' },
  { option: '8', label: '8 (41)' },
  { option: '8-5', label: '8.5 (41.5)' },
  { option: '9', label: '9 (42)' },
  { option: '9-5', label: '9.5 (42.5)' },
  { option: '10', label: '10 (43)' },
  { option: '10-5', label: '10.5 (43.5)' },
  { option: '11', label: '11 (44)' },
  { option: '11-5', label: '11.5 (44.5)' },
  { option: '12', label: '12 (45)' },
  { option: '12-5', label: '12.5 (45.5)' },
  { option: '13', label: '13 (46)' },
  { option: '13-5', label: '13.5 (46.5)' },
  { option: '14', label: '14 (47)' },
  { option: '14-5', label: '14.5 (47.5)' },
  { option: '15', label: '15 (48)' },
  { option: '15-5', label: '15.5 (48.5)' },
  { option: '16', label: '16 (49)' },
  { option: '16-5', label: '16.5 (49.5)' },
  { option: '17', label: '17 (49.5)' },
  { option: '18', label: '18 (50)' },
  { option: '19', label: '19 (50.5)' },
  { option: '20', label: '20 (51)' },
];

// Size groups: KIDS

const baby = {
  option: 'baby',
  label: 'Baby',
};

const babySizes = [
  { option: 'newborn', label: 'Newborn' },
  { option: '0-3-months', label: '0-3 Months' },
  { option: '3-6-months', label: '3-6 Months' },
  { option: '6-9-months', label: '6-9 Months' },
  { option: '9-12-months', label: '9-12 Months' },
  { option: '12-18-months', label: '12-18 Months' },
  { option: '24-months', label: '24 Months' },
  { option: 'one-size', label: 'One Size' },
  { option: 'not-specified', label: 'Not Specified' },
];

const toddlers = {
  option: 'toddlers',
  label: 'Toddlers',
};

const toddlersSizes = [
  { option: '2t', label: '2T' },
  { option: '3t', label: '3T' },
  { option: '4t', label: '4T' },
  { option: '5t', label: '5T' },
  { option: 'one-size', label: 'One Size' },
  { option: 'not-specified', label: 'Not Specified' },
];

const kids = {
  option: 'kids',
  label: 'Kids',
};

const kidsSizes = [
  { option: 'xs', label: 'XS (4-5)' },
  { option: 's', label: 'S (6-7)' },
  { option: 'm', label: 'M (8)' },
  { option: 'l', label: 'L (10-12)' },
  { option: 'xl', label: 'XL (14-16)' },
  { option: '2xl', label: '2XL (18)' },
  { option: 'one-size', label: 'One Size' },
];

const littleKidsShoesSize = {
  option: 'littleKidsShoes',
  label: "Little Kids' Shoes",
};

const littleKidsShoesSizes = [
  { option: '0', label: '0' },
  { option: '0-5', label: '0.5' },
  { option: '1', label: '1' },
  { option: '1-5', label: '1.5' },
  { option: '2', label: '2' },
  { option: '2-5', label: '2.5' },
  { option: '3', label: '3' },
  { option: '3-5', label: '3.5' },
  { option: '4', label: '4' },
  { option: '4-5', label: '4.5' },
  { option: '5', label: '5' },
  { option: '5-5', label: '5.5' },
  { option: '6', label: '6' },
  { option: '6-5', label: '6.5' },
  { option: '7', label: '7' },
  { option: '7-5', label: '7.5' },
  { option: '8', label: '8' },
  { option: '8-5', label: '8.5' },
  { option: '9', label: '9' },
  { option: '9-5', label: '9.5' },
  { option: '10', label: '10' },
  { option: '10-5', label: '10.5' },
  { option: '11', label: '11' },
  { option: '11-5', label: '11.5' },
  { option: '12', label: '12' },
  { option: '12-5', label: '12.5' },
  { option: '13', label: '13' },
  { option: '13-5', label: '13.5' },
];

const kidsShoesSize = {
  option: 'kidsShoes',
  label: "Kids' Shoes",
};

const kidsShoesSizes = [
  { option: '1', label: '1' },
  { option: '1-5', label: '1.5' },
  { option: '2', label: '2' },
  { option: '2-5', label: '2.5' },
  { option: '3', label: '3' },
  { option: '3-5', label: '3.5' },
  { option: '4', label: '4' },
  { option: '4-5', label: '4.5' },
  { option: '5', label: '5' },
  { option: '5-5', label: '5.5' },
  { option: '6', label: '6' },
  { option: '6-5', label: '6.5' },
  { option: '7', label: '7' },
];

export const sizeGroupOptions = {
  women: {
    clothing: {
      jeans: [adultPant],
      others: [womenFashion],
    },
    shoes: {
      others: [womenShoes],
    },
    accessories: {
      belts: [standardSize],
    },
  },
  men: {
    clothing: {
      blazers: [menSuitBlazerSizeGroup],
      bubsJeansPants: [menPantSizeGroup],
      others: [menFashion],
    },
    accessories: {
      belts: [standardSize],
    },
    shoes: {
      others: [menShoesSize],
    },
  },
  girls: {
    clothing: {
      others: [baby, toddlers, kids],
    },
    shoes: {
      others: [littleKidsShoesSize, kidsShoesSize],
    },
  },
  boys: {
    clothing: {
      others: [baby, toddlers, kids],
    },
    shoes: {
      others: [littleKidsShoesSize, kidsShoesSize],
    },
  },
};

export const allSizeOptions = [
  ...adultPantSizes,
  ...womenFashionSizes,
  ...womenShoesSizes,
  ...standardSizes,
  ...mensSuitBlazerSizes,
  ...menPantSizes,
  ...mensFashionSizes,
  ...mensShoesSizes,
  ...babySizes,
  ...toddlersSizes,
  ...kidsSizes,
  ...littleKidsShoesSizes,
  ...kidsShoesSizes,
];

export const sizeOptions = {
  women: {
    clothing: {
      jeans: {
        [adultPant.option]: adultPantSizes,
      },
      others: {
        [womenFashion.option]: womenFashionSizes,
      },
    },
    shoes: {
      others: {
        [womenShoes.option]: womenShoesSizes,
      },
    },
    accessories: {
      belts: {
        [standardSize.option]: standardSizes,
      },
    },
  },
  men: {
    clothing: {
      blazers: {
        [menSuitBlazerSizeGroup.option]: mensSuitBlazerSizes,
      },
      bubsJeansPants: {
        [menPantSizeGroup.option]: menPantSizes,
      },
      others: {
        [menFashion.option]: mensFashionSizes,
      },
    },
    accessories: {
      belts: {
        [standardSize.option]: standardSizes,
      },
    },
    shoes: {
      others: {
        [menShoesSize.option]: mensShoesSizes,
      },
    },
  },
  girls: {
    clothing: {
      others: {
        [baby.option]: babySizes,
        [toddlers.option]: toddlersSizes,
        [kids.option]: kidsSizes,
      },
    },
    shoes: {
      others: {
        [littleKidsShoesSize.option]: littleKidsShoesSizes,
        [kidsShoesSize.option]: kidsShoesSizes,
      },
    },
  },
  boys: {
    clothing: {
      others: {
        [baby.option]: babySizes,
        [toddlers.option]: toddlersSizes,
        [kids.option]: kidsSizes,
      },
    },
    shoes: {
      others: {
        [littleKidsShoesSize.option]: littleKidsShoesSizes,
        [kidsShoesSize.option]: kidsShoesSizes,
      },
    },
  },
};
