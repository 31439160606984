/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import pick from 'lodash/pick';
import { storableError } from '../../util/errors';
import { confirmPrivileged, getShippingRates, initiatePrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import * as log from '../../util/log';
import { getProcess } from '../../transactions/transaction';

const initialState = {
  listing: null,
  orderData: null,
  // transaction
  transaction: null,
  speculatedTransaction: null,
  speculateTransactionInProgress: false,

  listingAvailableStock: 0,

  speculateTransactionError: null,
  initiateTransactionError: null,
  confirmPaymentIntentError: null,
  confirmTransactionError: null,

  // shipping
  shippingRates: [],
  fetchShippingRatesInProgress: false,
  fetchShippingRatesError: null,
};

const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

const FETCH_SHIPPING_RATES_REQUEST = 'app/CheckoutPage/FETCH_SHIPPING_RATES_REQUEST';
const FETCH_SHIPPING_RATES_SUCCESS = 'app/CheckoutPage/FETCH_SHIPPING_RATES_SUCCESS';
const FETCH_SHIPPING_RATES_ERROR = 'app/CheckoutPage/FETCH_SHIPPING_RATES_ERROR';

const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

const INITIATE_TRANSACTION_REQUEST = 'app/CheckoutPage/INITIATE_TRANSACTION_REQUEST';
const INITIATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/INITIATE_TRANSACTION_SUCCESS';
const INITIATE_TRANSACTION_ERROR = 'app/CheckoutPage/INITIATE_TRANSACTION_ERROR';

const CONFIRM_PAYMENT_INTENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_INTENT_REQUEST';
const CONFIRM_PAYMENT_INTENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_INTENT_SUCCESS';
const CONFIRM_PAYMENT_INTENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_INTENT_ERROR';

const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

const RETRIEVE_AND_CONFIRM_REQUEST = 'app/CheckoutPage/RETRIEVE_AND_CONFIRM_REQUEST';
const RETRIEVE_AND_CONFIRM_SUCCESS = 'app/CheckoutPage/RETRIEVE_AND_CONFIRM_SUCCESS';
const RETRIEVE_AND_CONFIRM_ERROR = 'app/CheckoutPage/RETRIEVE_AND_CONFIRM_ERROR';

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case FETCH_SHIPPING_RATES_REQUEST:
      return { ...state, fetchShippingRatesInProgress: true, fetchShippingRatesError: null };
    case FETCH_SHIPPING_RATES_SUCCESS:
      return {
        ...state,
        speculatedTransaction: null,
        fetchShippingRatesInProgress: false,
        shippingRates: payload,
      };
    case FETCH_SHIPPING_RATES_ERROR:
      return { ...state, fetchShippingRatesInProgress: false, fetchShippingRatesError: payload };
    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculatedTransaction: null,
        speculateTransactionError: null,
        speculateTransactionInProgress: true,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return { ...state, speculatedTransaction: payload, speculateTransactionInProgress: false };
    case SPECULATE_TRANSACTION_ERROR:
      return {
        ...state,
        speculateTransactionError: payload,
        speculateTransactionInProgress: false,
      };
    case INITIATE_TRANSACTION_REQUEST:
      return { ...state, initiateTransactionError: null };
    case INITIATE_TRANSACTION_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_TRANSACTION_ERROR:
      return { ...state, initiateTransactionError: payload };
    case CONFIRM_PAYMENT_INTENT_REQUEST:
      return { ...state, confirmPaymentIntentError: null };
    case CONFIRM_PAYMENT_INTENT_SUCCESS:
      return { ...state };
    case CONFIRM_PAYMENT_INTENT_ERROR:
      return { ...state, confirmPaymentIntentError: payload };
    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmTransactionError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return { ...state };
    case CONFIRM_PAYMENT_ERROR:
      return { ...state, confirmTransactionError: payload };
    default:
      return state;
  }
};

const fetchShippingRatesRequest = () => ({ type: FETCH_SHIPPING_RATES_REQUEST });

export const fetchShippingRatesSuccess = rates => ({
  type: FETCH_SHIPPING_RATES_SUCCESS,
  payload: rates,
});

const fetchShippingRatesError = e => ({
  type: FETCH_SHIPPING_RATES_ERROR,
  error: true,
  payload: e,
});

export const fetchShippingRates = ({ listingId, shippingLocation, quantity }) => async dispatch => {
  try {
    dispatch(fetchShippingRatesRequest());
    const response = await getShippingRates({
      listingId,
      destination: shippingLocation,
      quantity,
    });
    const { rates } = response.data;
    dispatch(fetchShippingRatesSuccess(rates));
  } catch (e) {
    dispatch(fetchShippingRatesError(storableError(e)));
  }
};

const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });
const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: transaction,
});

const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const speculateTransaction = ({ orderData, bodyParams, queryParams }) => async dispatch => {
  try {
    dispatch(speculateTransactionRequest());
    const response = await initiatePrivileged({
      orderData,
      bodyParams,
      queryParams,
      isSpeculative: true,
    });
    const [tx] = denormalisedResponseEntities(response);
    dispatch(speculateTransactionSuccess(tx));
    return tx;
  } catch (e) {
    dispatch(speculateTransactionError(storableError(e)));
    throw e;
  }
};

const initiateTransactionRequest = () => ({ type: INITIATE_TRANSACTION_REQUEST });
const initiateTransactionSuccess = transaction => ({
  type: INITIATE_TRANSACTION_SUCCESS,
  payload: transaction,
});

const initiateTransactionError = e => ({
  type: INITIATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const initiateTransaction = ({
  orderData,
  transition,
  processAlias,
  listingId,
  protectedData,
  transactionId,
}) => async dispatch => {
  try {
    dispatch(initiateTransactionRequest());
    const { quantity } = orderData;
    const queryParams = {
      include: ['listing', 'customer', 'provider'],
      expand: true,
    };
    const bodyParams = transactionId
      ? {
          id: transactionId,
          transition,
          params: {
            listingId,
            stockReservationQuantity: quantity,
          },
        }
      : {
          transition,
          processAlias,
          params: {
            listingId,
            stockReservationQuantity: quantity,
          },
        };
    const response = await initiatePrivileged({
      orderData,
      bodyParams,
      queryParams,
    });
    dispatch(addMarketplaceEntities(response));
    const [tx] = denormalisedResponseEntities(response);
    dispatch(initiateTransactionSuccess(tx));
    return tx;
  } catch (e) {
    log.error(e, 'initiate-transaction-failed', {
      listingId,
      transition,
      processAlias,
      protectedData,
      orderData,
    });
    dispatch(initiateTransactionError(storableError(e)));
    throw e;
  }
};

const confirmPaymentIntentRequest = () => ({ type: CONFIRM_PAYMENT_INTENT_REQUEST });
const confirmPaymentIntentSuccess = () => ({
  type: CONFIRM_PAYMENT_INTENT_SUCCESS,
});

const confirmPaymentIntentError = e => ({
  type: CONFIRM_PAYMENT_INTENT_ERROR,
  error: true,
  payload: e,
});

const STRIPE_PI_HAS_PASSED_CONFIRM = ['processing', 'requires_capture', 'canceled', 'succeeded'];

export const confirmPaymentIntent = ({
  clientSecret,
  stripe,
  elements,
  returnUrl,
  transactionId,
  shippingAddress,
  customerName,
}) => async dispatch => {
  try {
    dispatch(confirmPaymentIntentRequest());
    const paymentIntentResponse = await stripe.retrievePaymentIntent(clientSecret);
    if (paymentIntentResponse.error) {
      throw paymentIntentResponse.error;
    }

    if (STRIPE_PI_HAS_PASSED_CONFIRM.includes(paymentIntentResponse?.paymentIntent?.status)) {
      dispatch(confirmPaymentIntentSuccess());
      return { transactionId };
    }
    elements.submit();
    const { address, country, postcode, state, city } = shippingAddress;
    const { error: confirmError } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnUrl,
        shipping: {
          address: {
            line1: address,
            country,
            postal_code: postcode,
            state,
            city,
          },
          name: customerName,
        },
      },
      redirect: 'if_required',
    });

    if (confirmError) {
      throw confirmError;
    }
    dispatch(confirmPaymentIntentSuccess());
    return { transactionId };
  } catch (err) {
    const e = err.error || storableError(err);
    dispatch(confirmPaymentIntentError(storableError(e)));
    const containsPaymentIntent = err.error && err.error.payment_intent;
    const { code, doc_url, message, payment_intent } = containsPaymentIntent ? err.error : {};
    const loggableError = containsPaymentIntent
      ? {
          code,
          message,
          doc_url,
          paymentIntentStatus: payment_intent.status,
        }
      : e;
    log.error(loggableError, 'stripe-handle-card-payment-failed', {
      stripeMessage: loggableError.message,
    });
    throw e;
  }
};

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });
const confirmPaymentSuccess = () => ({
  type: CONFIRM_PAYMENT_SUCCESS,
});
const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const confirmPayment = ({
  transactionId,
  transition,
  transitionParam = {},
}) => async dispatch => {
  try {
    dispatch(confirmPaymentRequest());
    const bodyParams = {
      id: transactionId,
      transition,
      params: transitionParam,
    };
    const queryParams = {
      include: ['booking', 'provider', 'customer'],
      expand: true,
    };

    const response = await confirmPrivileged({ bodyParams, queryParams });
    dispatch(addMarketplaceEntities(response));
    const [tx] = denormalisedResponseEntities(response);
    dispatch(confirmPaymentSuccess());
    return tx;
  } catch (e) {
    dispatch(confirmPaymentError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

const retrieveAndConfirmRequest = () => ({ type: RETRIEVE_AND_CONFIRM_REQUEST });
const retrieveAndConfirmSuccess = () => ({
  type: RETRIEVE_AND_CONFIRM_SUCCESS,
});
const retrieveAndConfirmError = e => ({
  type: RETRIEVE_AND_CONFIRM_ERROR,
  error: true,
  payload: e,
});

export const retrieveAndConfirm = ({ transactionId }) => async (dispatch, getState, sdk) => {
  try {
    dispatch(retrieveAndConfirmRequest());
    const showResponse = await sdk.transactions.show({
      id: transactionId,
    });
    let [tx] = denormalisedResponseEntities(showResponse);
    const process = getProcess(tx.attributes.processName);
    if (tx.attributes.lastTransition === process.transitions.REQUEST_PAYMENT) {
      tx = await dispatch(
        confirmPayment({
          transactionId,
          transition: process.transitions.CONFIRM_PAYMENT,
          transitionParam: {},
        })
      );
    }
    dispatch(retrieveAndConfirmSuccess());
    return tx;
  } catch (e) {
    dispatch(retrieveAndConfirmError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'retrieve-and-confirm-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

export default reducer;

// selectors
export const shippingRatesSelector = state => state.CheckoutPage.shippingRates;
export const currentTransactionSelector = state => state.CheckoutPage.transaction;
export const orderDataSelector = state => state.CheckoutPage.orderData;
export const listingAvailableStockSelector = state => state.CheckoutPage.listingAvailableStock;
export const fetchShippingRatesInProgressSelector = state =>
  state.CheckoutPage.fetchShippingRatesInProgress;
export const currentListingSelector = state => state.CheckoutPage.listing;
export const speculatedTransactionSelector = state => state.CheckoutPage.speculatedTransaction;
export const speculateTransactionInProgressSelector = state =>
  state.CheckoutPage.speculateTransactionInProgress;
export const errorsSelector = state => {
  const {
    speculateTransactionError,
    initiateTransactionError,
    confirmPaymentIntentError,
    confirmTransactionError,
    fetchShippingRatesError,
  } = state.CheckoutPage;
  return {
    speculateTransactionError,
    initiateTransactionError,
    confirmPaymentIntentError,
    confirmTransactionError,
    fetchShippingRatesError,
  };
};
