import React from 'react';
import { FormattedMessage } from 'react-intl';
import { func, string } from 'prop-types';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PrimaryButton } from '../../../../components';
import css from './HowToSell.module.css';
import howToSellBg from '../../../../assets/howToSell_bg.png';
import reCommerceBg from '../../../../assets/recommerce_bg.png';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';

const HOW_TO_SELL = 'HowToSell';
const RECOMMERCE = 'ReCommerce';

const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

function ContentSection(props) {
  const { onClick, id, img } = props;
  return (
    <div className={css.root} key={id}>
      <div className={classNames(css.container, { [css.recommerce]: id === RECOMMERCE })}>
        <div className={css.imgWrapper}>
          <img className={css.img} src={img} />
        </div>
        <div className={classNames(css.leftSide, { [css.recommerceSide]: id === RECOMMERCE })}>
          <div className={css.contentWrapper}>
            <div className={css.title}>
              <FormattedMessage id={`LandingPage.${id}.title`} />
            </div>
            {id === HOW_TO_SELL && (
              <div className={css.description}>
                <FormattedMessage id={`LandingPage.${id}.description`} />
              </div>
            )}
            <div className={css.content}>
              <FormattedMessage
                id={`LandingPage.${id}.content`}
                values={{
                  foundation: <FormattedMessage id={`LandingPage.${id}.foundation`} tagName="b" />,
                }}
              />
            </div>
            <PrimaryButton className={css.btn} onClick={onClick}>
              <FormattedMessage id={`LandingPage.${id}.titleBtn`} />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

ContentSection.propTypes = {
  id: string.isRequired,
  onClick: func.isRequired,
  img: string.isRequired,
};

export function HowToSell() {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const onClick = () => {
    const currentPathParams = {
      slug: draftSlug,
      id: draftId,
      type: 'new',
      tab: 'details',
    };
    const draftURI = createResourceLocatorString(
      'EditListingPage',
      routeConfiguration,
      currentPathParams,
      {}
    );
    history.push(draftURI);
  };
  return <ContentSection onClick={onClick} id={HOW_TO_SELL} img={howToSellBg} />;
}

export function ReCommerce() {
  return <ContentSection onClick={() => {}} id={RECOMMERCE} img={reCommerceBg} />;
}

export default {
  HowToSell,
  ReCommerce,
};
