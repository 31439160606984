import React from 'react';

const BellIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9.83984 21.72H14.1598"
        stroke="#616D69"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.2793 4.44003L4.4393 2.28003"
        stroke="#616D69"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7205 4.44003L19.5605 2.28003"
        stroke="#616D69"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5957 17.3999H19.4068L17.5989 8.96512C17.3261 7.68256 16.6211 6.53256 15.602 5.70745C14.5829 4.88233 13.3114 4.43213 12.0002 4.43213C10.6889 4.43213 9.41743 4.88233 8.39832 5.70745C7.37922 6.53256 6.6743 7.68256 6.40146 8.96512L4.5957 17.3999Z"
        stroke="#616D69"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellIcon;
