import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import women1 from '../../../../assets/women_1.png';
import women2 from '../../../../assets/women_2.png';
import men1 from '../../../../assets/men_1.png';
import men2 from '../../../../assets/men_2.png';
import kid1 from '../../../../assets/kids_1.png';
import kid2 from '../../../../assets/kids_2.png';

import {
  categories,
  subCategories,
  recommendedSubCategories,
} from '../../../../config/configListing';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import css from './NavBarSection.module.css';

const staticImages = {
  women1,
  women2,
  men1,
  men2,
  kid1,
  kid2,
};

function NavBarSection() {
  const listCategoriesOptions = categories.enumOptions;
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCates, setSubCates] = useState(null);
  const [recommend, setRecommend] = useState(null);

  const onSearchCate = item => {
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        {
          pub_category: item,
        }
      )
    );
  };

  const onSearchSubCate = (option, subCat) => {
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        {
          pub_category: option,
          pub_subCategory: subCat?.option,
        }
      )
    );
  };

  const onRecommended = (option, item) => {
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        {
          pub_category: option,
          keywords: item?.label,
        }
      )
    );
  };

  const handleCategoryLabelHover = id => () => {
    setSelectedCategory(id);
  };
  const handleCategoryLabelLeave = () => {
    setSelectedCategory(null);
  };

  useEffect(() => {
    const subs = subCategories.enumSections[selectedCategory] || undefined;
    const rec = recommendedSubCategories[selectedCategory] || undefined;
    setSubCates(subs);
    setRecommend(rec);
  }, [selectedCategory]);

  return (
    <div className={css.root}>
      <div className={css.menuWrapper} onMouseLeave={handleCategoryLabelLeave}>
        {listCategoriesOptions.map(item => {
          return (
            <div
              key={item.option}
              className={classNames(css.categoryLabel, {
                [css.categoryLabelHover]: selectedCategory === item.option,
              })}
              onMouseEnter={handleCategoryLabelHover(item.option)}
            >
              {item.label}
            </div>
          );
        })}
        {selectedCategory && (
          <div className={css.categoryPopup}>
            <div className={css.categoryPopupLeft}>
              <div className={css.popupTitle}>
                <FormattedMessage id="NavBarSection.categoryPopupTitle" />
              </div>
              <div className={css.poptupCategories}>
                {subCates?.slice(0, 12).map(sub => (
                  <div
                    key={sub.option}
                    className={css.popupCategory}
                    onClick={() => onSearchSubCate(selectedCategory, sub)}
                  >
                    {sub.label}
                  </div>
                ))}
              </div>
              <div className={css.seeAll} onClick={() => onSearchCate(selectedCategory)}>
                <FormattedMessage id="NavBarSection.seeAll" values={{ selectedCategory }} />
              </div>
            </div>

            <div className={css.categoryPopupRight}>
              {recommend && (
                <div className={css.recommendedTitle}>
                  <FormattedMessage id="NavBarSection.recommended" />
                </div>
              )}
              <div className={css.recommendedOptions}>
                <div className={css.recommendedLeft}>
                  {recommend?.primaryOptions?.map(item => {
                    const imgSrc = staticImages[item.image];
                    return (
                      <div
                        key={item.option}
                        className={css.primaryCard}
                        onClick={() => onRecommended(selectedCategory, item)}
                      >
                        <img
                          key={item.option}
                          src={imgSrc}
                          className={css.primaryCardImg}
                          alt={item.label}
                        />
                        <p className={css.primaryCardTitle}>{item.label}</p>
                      </div>
                    );
                  })}
                </div>
                <div className={css.recommendedRight}>
                  {recommend?.secondOptions?.map(item => {
                    return (
                      <div
                        key={item.option}
                        className={css.secondCardLabel}
                        onClick={() => onRecommended(selectedCategory, item)}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBarSection;
