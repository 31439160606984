import { bool, string } from 'prop-types';
import React from 'react';

const IconUser = ({ fill, stroke, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M11.9997 10.92C13.1454 10.92 14.2442 10.4649 15.0544 9.65473C15.8645 8.84457 16.3197 7.74576 16.3197 6.60003C16.3197 5.45429 15.8645 4.35549 15.0544 3.54533C14.2442 2.73517 13.1454 2.28003 11.9997 2.28003C10.854 2.28003 9.75514 2.73517 8.94499 3.54533C8.13483 4.35549 7.67969 5.45429 7.67969 6.60003C7.67969 7.74576 8.13483 8.84457 8.94499 9.65473C9.75514 10.4649 10.854 10.92 11.9997 10.92Z"
        stroke="#616D69"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M21.1661 21.72C20.4973 19.8229 19.2564 18.18 17.6145 17.0179C15.9726 15.8558 14.0106 15.2317 11.9991 15.2317C9.98752 15.2317 8.02552 15.8558 6.38363 17.0179C4.74173 18.18 3.50083 19.8229 2.83203 21.72H21.1661Z"
        stroke="#616D69"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </svg>
  );
};

IconUser.defaultProps = {
  stroke: 'currentColor',
};

IconUser.propTypes = {
  stroke: string,
};
export default IconUser;
