import React from 'react';

const IconArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.334731 0.334735C0.781051 -0.111578 1.50466 -0.111578 1.95098 0.334735L8.40411 6.78782C9.07349 7.45729 9.07349 8.54271 8.40411 9.21218L1.95098 15.6653C1.50466 16.1115 0.781051 16.1115 0.334731 15.6653C-0.111577 15.219 -0.111577 14.4953 0.334731 14.049L6.38376 8L0.334731 1.95098C-0.111577 1.50466 -0.111577 0.781048 0.334731 0.334735Z"
        fill="#616D69"
      />
    </svg>
  );
};

export default IconArrow;
