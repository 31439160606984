import React from 'react';
import { FormattedMessage } from 'react-intl';
import googlePlayBtn from '../../../../assets/googleplay_btn.png';
import appstoreBtn from '../../../../assets/appstore_btn.png';
import css from './GetAppSection.module.css';

function GetAppSection() {
  return (
    <div className={css.root}>
      <div className={css.mainWrapper}>
        <div className={css.contentWrapper}>
          <div className={css.heading}>
            <FormattedMessage id="LandingPage.GetAppSection.heading" />
          </div>
          <div className={css.content}>
            <FormattedMessage id="LandingPage.GetAppSection.content" />
          </div>
          <div className={css.buttonWrapper}>
            <div className={css.btn}>
              <img src={appstoreBtn} alt="appstore" className={css.img} />
            </div>
            <div className={css.btn}>
              <img src={googlePlayBtn} alt="googleplay" className={css.img} />
            </div>
          </div>
        </div>
        <div className={css.images}>
          <div className={css.desktopImg} />
          <div className={css.mobileImg} />
        </div>
      </div>
    </div>
  );
}

export default GetAppSection;
