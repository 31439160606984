import React from 'react';
import moment from 'moment';
import { object } from 'prop-types';
import css from './NewCard.module.css';

function NewsCard(props) {
  const { item } = props;
  if (!item) {
    return <div />;
  }

  const { id, image_url: imageUrl, title, date_posted: datePosted } = item || {};

  return (
    <div key={id} className={css.card}>
      <img src={imageUrl} alt={title} className={css.img} />

      <div className={css.main}>
        <div className={css.dates}>{moment(datePosted).format('MMMM DD, YYYY')}</div>
        <div className={css.title}>{title}</div>
        <div className={css.description}>-</div>
        <div className={css.tags}>
          <div className={css.tag1}>TAG: #1</div>
          <div className={css.tag2}>TAG: #2</div>
        </div>
      </div>
    </div>
  );
}

NewsCard.propTypes = {
  item: object,
};

NewsCard.defaultProps = {
  item: null,
};

export default NewsCard;
