import React from 'react';
import { useIntl } from 'react-intl';
import { string, object } from 'prop-types';
import FieldSelectComponent from '../FieldSelect/FieldSelect';
import { required } from '../../util/validators';
import { requiredFieldWithAsterisk } from '../../util/uiHelpers';

const FieldSelect = requiredFieldWithAsterisk(FieldSelectComponent);

const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

const FieldSingleSelect = props => {
  const intl = useIntl();
  const { name, fieldConfig, defaultRequiredMessage, className } = props;
  const { enumOptions = [], saveConfig } = fieldConfig || {};
  const { label, placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage ||
    intl.formatMessage({ id: 'CustomExtendedDataField.placeholderSingleSelect' });
  const filterOptions = createFilterOptions(enumOptions);

  return (
    <FieldSelect className={className} name={name} id={name} label={label} {...validateMaybe}>
      <option disabled value="">
        {placeholder}
      </option>
      {filterOptions.map(optionConfig => {
        const { key } = optionConfig;
        return (
          <option key={key} value={key}>
            {optionConfig.label}
          </option>
        );
      })}
    </FieldSelect>
  );
};

FieldSingleSelect.defaultProps = {
  className: null,
  defaultRequiredMessage: null,
};

FieldSingleSelect.propTypes = {
  className: string,
  name: string.isRequired,
  fieldConfig: object.isRequired,
  defaultRequiredMessage: string,
};

export default FieldSingleSelect;
