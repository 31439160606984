import React from 'react';
import { array, bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';

const { types } = require('sharetribe-flex-sdk');

const { Money } = types;

const LineItemTotalPrice = props => {
  const {
    isSyntheticBreakdown,
    marketplaceCurrency,
    transaction,
    isProvider,
    lineItems,
    intl,
  } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const payoutTotal = lineItems.filter(
    item => item.code !== LINE_ITEM_SHIPPING_FEE
  ).reduce(
    (accumulator, currentValue) => accumulator + currentValue.lineTotal.amount, 0
  );

  const getTotalPrice = () => {
    if (isSyntheticBreakdown) {
      return new Money(payoutTotal, marketplaceCurrency);
    }
    return isProvider ? transaction.attributes.payoutTotal : transaction.attributes.payinTotal;
  };

  const totalPrice = getTotalPrice();
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
  isSyntheticBreakdown: bool,
  marketplaceCurrency: string,
  lineItems: array,
};

export default LineItemTotalPrice;
